var routes = [{
  path: '/',
  component: function component() {
    return import('layouts/GuestLayout.vue');
  },
  children: [{
    path: '',
    component: function component() {
      return import('pages/Index.vue');
    }
  }, {
    path: '/login',
    name: 'login',
    component: function component() {
      return import('pages/Access/login');
    }
  }, {
    path: '/signup',
    name: 'signup',
    component: function component() {
      return import('pages/Access/signup');
    }
  },
  // { path: '/signup?:idserv&:cur&:hash', name: 'signup', component: () => import('pages/Access/signup'), props: true },
  {
    path: '/resetpsw',
    name: 'resetpsw',
    component: function component() {
      return import('pages/Access/resetpsw');
    }
  }, {
    path: '/password',
    name: 'password',
    component: function component() {
      return import('pages/Access/password');
    },
    props: true
  }]
}, {
  path: '/q',
  component: function component() {
    return import('layouts/QueryLayout.vue');
  },
  children: [{
    path: '/smaquery/:auth',
    name: 'smaquery',
    props: true,
    component: function component() {
      return import('pages/Access/smaQuery');
    }
  }]
}, {
  path: '/auth',
  component: function component() {
    return import('layouts/AuthLayout.vue');
  },
  children: [{
    path: '',
    component: function component() {
      return import('pages/Index.vue');
    }
  }, {
    path: '/home',
    name: 'home',
    component: function component() {
      return import('pages/home');
    }
  }, {
    path: '/analytics',
    name: 'analytics',
    component: function component() {
      return import('pages/Analytics/AnalyticsTab');
    }
  }, {
    path: '/customer',
    name: 'customer',
    component: function component() {
      return import('pages/Customer/Customer');
    }
  }, {
    path: '/customer/:id',
    name: 'smaCustomerProfile',
    props: true,
    component: function component() {
      return import('pages/Customer/smaCustomerProfile');
    }
  }, {
    path: '/request/tab',
    name: 'requestTab',
    component: function component() {
      return import('pages/Request/RequestTab');
    }
  }, {
    path: '/request/board',
    name: 'requestBoard',
    component: function component() {
      return import('pages/Request/Board/RequestBoard');
    }
  }, {
    path: '/request/:id',
    name: 'requestEdit',
    props: true,
    // (route) => ({ id: Number(route.params.id) }),
    component: function component() {
      return import('pages/Request/RequestEdit');
    }
  }, {
    path: '/profile/:idOrLogin?',
    name: 'profile',
    props: true,
    // (route) => ({ idOrLogin: route.params.idOrLogin, idService: route.query.idService }),
    component: function component() {
      return import('pages/Access/smaProfileUpdate');
    }
  }, {
    path: '/service',
    name: 'service',
    component: function component() {
      return import('pages/Service/ServiceTab');
    }
  }, {
    path: '/service/:id',
    name: 'serviceEdit',
    props: true,
    // (route) => ({ id: Number(route.params.id) }),
    component: function component() {
      return import('pages/Service/ServiceEdit');
    }
  }, {
    path: '/request-tender/:id',
    name: 'requestTender',
    props: true,
    component: function component() {
      return import('pages/Request/RequestTender');
    }
  }, {
    path: '/request-actual/:id',
    name: 'requestActual',
    props: true,
    component: function component() {
      return import('pages/Request/RequestActual');
    }
  }, {
    path: '/request-start/:id',
    name: 'requestStart',
    props: true,
    component: function component() {
      return import('pages/Request/RequestStartWork');
    }
  }, {
    path: '/all-offers/:id',
    name: 'requestAllOffers',
    props: true,
    component: function component() {
      return import('pages/Request/RequestAllOffers');
    }
  }, {
    path: '/exec-offer/:id&:idAcc',
    name: 'requestExecOffer',
    props: true,
    component: function component() {
      return import('pages/Request/RequestExecOffer');
    }
  }, {
    path: '/review/upsert',
    name: 'review',
    component: function component() {
      return import('pages/Review/smaReviewUpsert');
    }
  }, {
    path: '/review/executor/list/:id',
    name: 'reviewExecList',
    component: function component() {
      return import('pages/Review/smaReviewExecList');
    }
  }, {
    path: '/review/customer/list/:id',
    name: 'reviewCustList',
    component: function component() {
      return import('pages/Review/smaReviewCustList');
    }
  }, {
    path: '/refs',
    name: 'administrator',
    component: function component() {
      return import('pages/Refs/RefsMenu.vue');
    }
  }, {
    path: '/refs/currency',
    component: function component() {
      return import('pages/Refs/smaCurrency');
    }
  }, {
    path: '/refs/language',
    component: function component() {
      return import('pages/Refs/comLang');
    }
  }, {
    path: '/refs/calendar',
    component: function component() {
      return import('pages/Refs/smaCalendar');
    }
  }, {
    path: '/refs/calendarday',
    component: function component() {
      return import('pages/Refs/smaCalendarDay');
    }
  }, {
    path: '/refs/dblocale',
    name: 'dblocale',
    component: function component() {
      return import('pages/Refs/dblocaleTab');
    }
  }, {
    path: '/refs/smacompany',
    name: 'smacompanyTab',
    component: function component() {
      return import('pages/Refs/smaCompanyTab');
    }
  }, {
    path: '/refs/smacompanyRec/:id&:card',
    name: 'smacompanyRec',
    component: function component() {
      return import('pages/Refs/smaCompanyRec');
    }
  }, {
    path: '/refs/smafreelance',
    name: 'smafreelanceTab',
    component: function component() {
      return import('pages/Refs/smaFreelanceTab');
    }
  }, {
    path: '/refs/smafreelanceRec/:id&:card',
    name: 'smafreelanceRec',
    component: function component() {
      return import('pages/Refs/smaFreelanceRec');
    }
  }, {
    path: '/refs/smaquerysubject',
    name: 'smaquerysubject',
    component: function component() {
      return import('pages/Refs/smaQuerySubject');
    }
  }, {
    path: '/dashboard',
    name: 'dashboard',
    component: function component() {
      return import('pages/Dashboard/dashboardTab');
    }
  }, {
    path: '/dashboardRec/:id&:card',
    name: 'dashboardRec',
    component: function component() {
      return import('pages/Dashboard/dashboardRec');
    }
  }, {
    path: '/acsaccount',
    name: 'acsaccountTab',
    component: function component() {
      return import('pages/Access/acsaccountTab');
    }
  }, {
    path: '/acsaccountRec/:id&:card',
    name: 'acsaccountRec',
    component: function component() {
      return import('pages/Access/acsaccountRec');
    },
    props: true
  }, {
    path: '/logout',
    name: 'logout',
    component: function component() {
      return import('pages/Access/login');
    }
  }, {
    path: '/acsrole',
    name: 'acsrole',
    component: function component() {
      return import('pages/Access/acsrole');
    }
  }, {
    path: '/a/:hash',
    name: 'shortLink',
    props: true,
    component: function component() {
      return import('pages/ShortLink/ShortLinkOpen');
    }
  }]
}];

// Always leave this as last one
if (process.env.MODE !== 'ssr') {
  routes.push({
    path: '*',
    name: '404',
    component: function component() {
      return import('pages/Error404.vue');
    }
  });
}
export default routes;