export var smaCustomer = {
  // ADMINISTRATOR mode, administrating users and companies
  lab: {
    cardRec: '',
    cardTab: ''
  },
  name: 'Firma',
  actReviews: 'Bewertungen',
  url: 'Website',
  phone: 'Telefon',
  email: 'E-Mail',
  country: 'Land der Eintragung',
  note: 'Über',
  save: 'Speichern',
  employees: 'Angestellte',
  employee: 'Angestellter',
  jobPosition: 'Position',
  companyRec: 'Firma',
  companyTab: 'Firmen',
  requisites: 'Firmendetails',
  urName: 'Offizieller Name',
  regAddr: 'Registrierungsadresse',
  inn: 'UID-Nummer',
  kpp: 'Registrierungscode',
  bankReq: 'Bankverbindung',
  agreement: 'Vertragsdetails'
};
export var customer = {
  lab: {
    rec: 'Klient',
    tab: 'Klienten',
    name: 'Name',
    rate: 'Bewertung',
    note: 'Über',
    templateNum: 'Zahlenvorlage anfordern',
    counterNum: 'Zahlenzähler anfordern'
  },
  act: {},
  msg: {}
};