export default {
  // ADMINISTRATOR mode, administrating users
  lab: {
    object: 'Users',
    cardRec: 'User',
    cardRse: 'Filter Users',
    cardTab: 'Users',
    login: 'User account',
    name: 'Surname Name',
    jobposition: 'Position',
    phone: 'Phone number',
    email: 'E-mail',
    usertype: 'User type',
    type: 'Type',
    exekind: 'Contributor type',
    executor: 'Contributor',
    customer: 'Client',
    smart: 'Platform employee',
    customerfirm: 'Company',
    kindP: 'Individual',
    kindF: 'Legal entity',
    kindG: 'Virtual team',
    kindPt: 'Individual',
    kindFt: 'Entity',
    kindGt: 'Team',
    ava: 'Photo'
  },
  act: {
    actResetPsw: 'Reset Password'
  },
  msg: {
    confirmResetPsw: 'Are you sure you want to reset the password?',
    resetdone: 'The password reset successfully'
  }
};