export default {
  // REQUESTS & PBIS modeF, creating, processing the request (a clinet view)
  lab: {
    rec: 'Request',
    name: 'Name',
    note: 'Comments',
    num: 'Number',
    addreqcap: 'New request',
    servname: 'Product / Service',
    description: 'Description',
    docs: 'Documents',
    yourdocs: 'Your documents',
    customerdocs: 'Client documents',
    start: 'Start',
    term: 'Deadline',
    minTerm: 'Minimum deadline',
    termForCustomer: 'Expected deadline',
    val: 'Price',
    accordion1: 'Request Approval',
    accordion2: 'Implementation',
    accordion3: 'Testing',
    accordion4: 'Architectural Assurance / Deployment',
    accorditionIniwin: 'Contributor Selection',
    accorditionTop5: 'Top-5 Contributors',
    stages: 'Steps and Duration',
    event: 'Step',
    eventdtstart: 'Start',
    eventdt: 'Finish',
    stname: 'Status',
    state: 'State',
    customer_name: 'Client',
    executor_name: 'Contributor',
    initiator_name: 'Requester',
    responsible: 'Responsible',
    expected: 'Exp. deadline',
    expectedfull: 'Expected deadline',
    period: 'Period',
    dt: 'Date of creation',
    tillTrade: 'Until the end of bidding: ',
    tillInnerExec: 'Until the end of assigning an in-house contributor: ',
    tillChoose: 'Until the end of selecting a winning bidder: ',
    promptDecline: 'Reason for rejection',
    promptComment: 'Comments (optional)',
    promptAlter: 'Reason for selecting an alternate winner',
    saveMsg: 'Saving...',
    mainOffer: 'Main Proposal',
    laborCosts: 'Efforts',
    hour: 'hour',
    altOffer: 'Alternative Proposal',
    proposedTerm: 'Proposed deadline',
    propTerm: 'Proposed deadline',
    discount: 'Discount',
    noteExec: "Contributor's comments",
    actExecAcceptSuccess: 'The request is accepted',
    exec: 'Execution',
    usePower: 'Take the opportunity to improve your proposal within rebidding.',
    costDeviationGT: 'Your proposed efforts are {devCost}% above average',
    costDeviationLT: 'At bidding, your efforts were among the best! Please note that at rebidding, other contributors can improve their proposals.',
    costDeviationEQ: 'Your proposed efforts correspond to the average',
    termDeviationGT: 'Your proposed terms are {devTerm}% longer than average',
    termDeviationLT: 'Your proposed terms are {devTerm}% shorter than average',
    termDeviationEQ: 'Your proposed terms correspond to the average',
    termDeviationNC: 'According to the results of the auction, your labor costs were among the best! Please note that',
    termDeviationIA: 'at the rebidding, other performers can improve their offers.',
    medalFirst1: 'Congratulations! Your ',
    medalFirst2: 'alternative ',
    medalFirst3: 'proposal has been accepted.',
    medalFirst4: 'You have been assigned as a contributor to the request.',
    medalSecond: 'Please confirm the start of work on the request.',
    winnerSecond1: 'We recommend to select ',
    winnerSecond2: 'the main ',
    winnerSecond3: 'the alternative ',
    winnerSecond4: 'proposal.',
    winnerThird: '{winName} completes work on time in {winProc}% of cases.',
    hcolSt1: 'Status before',
    hcolSt2: 'Status after',
    hcolReason: 'Reason for rejection',
    hcolComment: 'Comments',
    chooseExecutor: 'Select the contributor',
    tradeWinner: 'The winning bidder',
    otherExecs: 'Other bidders',
    initChosen: 'The contributor selected by the requester',
    execRecom: 'We Recommend the Contributor',
    testingCussessful: 'Testing successful',
    testingFailed: 'Testing failed',
    deployCussessful: 'Architectural assurance / deployment successful',
    deployFailed: 'Architectural assurance / deployment failed',
    execChosen: 'The contributor is selected',
    valDeltaGT: 'The proposal is {valDelta}% more expensive than ',
    valDeltaLT: 'The proposal is {valDelta}% cheaper than ',
    valDeltaEQ: 'The proposal corresponds to ',
    platformRecom0: 'what was recommended by the platform',
    platformRecom: 'what was recommended by the platform',
    daysDelta0: 'The deadline matches the original one',
    daysDelta: 'The deadline is {daysDelta} working days later than the original one',
    smartChoose: 'Platform Selection',
    innerChoose: 'Assigning an In-house Contributor',
    reqInWork: 'Requests in progress',
    free: 'Free',
    busy: 'Busy',
    overloaded: 'Overloaded',
    platformName: 'Smart IT Platform',
    tenders: '0 submitted proposals | 1 submitted proposal | {n} submitted proposals',
    cntRebids: '0 rebiddings. | 1 rebidding. | {n} rebiddings.',
    platformChooseText: 'The winner is selected based on the criteria price ({valScale}%), quality ({qualScale}%), minimum rating ({minrate}) among ',
    platformChooseText1: ' according to the results of the bidding and ',
    coordChoose: 'The contributor selection approved',
    initChooseLongDesc1: '',
    initChooseLongRating: '(rating {valRate})',
    initChooseLongDesc2: ' has been selected as a contributor with ',
    initChooseLongDesc3Main: ' the main ',
    initChooseLongDesc3Alter: ' the alternative ',
    initChooseLongDesc4: ' proposal with the price of ',
    initChooseLongDesc5: ' and ',
    initChooseLongDesc6Main: ' the requested ',
    initChooseLongDesc6Alter: ' the proposed ',
    initChooseLongDesc7: ' deadline ',
    platformChooseLongDesc1: 'This proposal differs from the one recommended by the Smart IT platform: the contributor ',
    platformChooseLongDesc2: ' with ',
    chosenOffer: 'Selected proposal',
    initOfferChosen: 'The requester selection',
    innerChooseLongDesc1: '',
    innerChooseLongDesc2: ' has been assigned as a contributor',
    offerNewValue: 'Propose a new price:',
    withoutChangeCost: 'Without changing the price of the request: ',
    innerComment: 'Internal comments',
    comment4Exec: 'Comments for the contributor',
    changeReq: 'Changing the Request',
    changeReqReq: 'Change request',
    changeReqReqConf: 'Change request confirmed',
    changeReqReqRej: 'Change request rejected',
    newReqValue: 'Proposed payment amount: ',
    newReqValueProc: ', which is {proc}% of the request price.',
    comment4Init: 'Comments for the client',
    cancelReq: 'Cancellation of the Request',
    offerAmount: 'Propose the payment amount for the request:',
    fullRequestCost: 'The full price of the request: ',
    newCostValue: 'Define the payment amount: ',
    recommendVal: '{recProc}% of the request price: ',
    recommend: '(recommended)',
    spendedVal: 'In proportion to the time spent ({spendProc}%): ',
    changeReqCan: 'Cancellation Request',
    cancelReqInfo1: ' This is ',
    cancelReqInfo2: 'the standard condition ',
    cancelReqInfo3: 'for cancellation at this stage.',
    cancelReqReqConf: 'Cancellation request confirmed',
    cancelReqReqRej: 'Cancellation request rejected',
    leaveComment: 'Comments',
    reqAgree: 'Request approved',
    reqAgreeToCoord: 'Request sent for approval to the product owner / coordinator',
    reqCancel: 'Request rejected',
    indPeriod: 'Define the deadline when you could perform the request cheaper',
    actualizing: 'Status update',
    sendToTest: 'Submitted for testing',
    sendToDeploy: 'Submitted for architectural assurance / deployment',
    sendToComplete: 'Completed',
    makingRequest: 'Creating request',
    editingRequest: 'Updating request',
    smartWin: 'Selecting a contributor in automatic mode',
    rating: 'Rating',
    initRequest: 'Creating request',
    startWorkConfirm: 'Confirming the start of work',
    offerDeclined: 'The start of work is not confirmed',
    offerDeclinedTxt: 'The contributor has not confirmed the start of work on time',
    notActual: 'Not valid',
    tradeInfo1: 'The request is at the {stcode}{numreb} stage. The remaining time until the end of {stcode}: ',
    tradeInfo1Bid: 'bidding',
    tradeInfo1Rebid: 'rebidding',
    tradeInfo2: '{cur} {alltenders} out of {allexecs} submitted their proposals including {allinner} and {allouter}. Proposals with a deadline corresponding to the requested one: {allactual}.',
    tradeInfo2CurMoment: 'At the moment,',
    tradeInfo2CurResult: 'Based on bidding results,',
    tradeInfo2CurResult2: 'Based on bidding and rebidding results,',
    tradeInfo2AllExecs: '0 accredited contributors | {n} accredited contributor | {n} accredited contributors | {n} accredited contributors',
    tradeInfo2AllInner: '0 in-house | {n} in-house | {n} in-house | {n} in-house',
    tradeInfo2AllOuter: '0 outsourced contributors | {n} outsourced contributor | {n} outsourced contributors | {n} outsourced contributors',
    tradeInfo3: 'The request {allrej} including {allinnerrej} and {allouterrej}.',
    tradeInfo3Execs: 'was rejected by 0 contributors | was rejected by {n} contributors | was rejected by {n} contributors | was rejected by {n} contributors',
    tradeInfo4Req: 'At the end of bidding, the clarification of requirements will start, after which rebidding will follow.',
    tradeInfo4BidRebid: 'At the end of bidding, rebidding will start (the number of iterations: {rebidcnt}), after which the contributor selection will follow.',
    tradeInfo4Exec: 'At the end of bidding, the contributor selection will follow.',
    tradeInfo4Rebid: 'At the end of rebidding (the number of iterations: {rebidcnt}), the contributor selection will follow.',
    tradeInfo5Res: 'In the course of rebidding, ',
    tradeInfo5Execs: '0 contributors improved | {n} contributor improved | {n} contributors improved | {n} contributors improved ',
    tradeInfo5ExecsDown: '0 contributors worsened | {n} contributor worsened | {n} contributors worsened | {n} contributors worsened ',
    tradeInfo5Con: ' the proposal compared to bidding. On average, the price of the main proposal {avgrebid}%, the maximum decrease was {maxrebid}%.',
    tradeInfo5ConLT: 'decreased by ',
    tradeInfo5ConGT: 'increased by ',
    endOfBid: 'Bidding completed',
    endOfRebid: 'Bidding and rebidding completed',
    tradeInfoReq2: 'The request is at the stage of the clarification of requirements.',
    tradeInfoReqWLink31: 'We ask you to hold meetings ',
    tradeInfoReqWLink32: 'with bidders',
    tradeInfoReqWLink33: ' to present their current proposals and conduct negotiations.',
    tradeInfoReq5: 'To ensure a level playing field and improve the competition, you can hold a joint meeting with all bidders to clarify the requirements. This will allow them to gain equal access to information, including answers to questions from other contributors.',
    isClarificationNeed: 'Do you need to clarify the requirements for the request?',
    stateRequrements: 'Clarification of the requirements',
    tradeInfoReqRebid: 'At the end of this stage, rebidding will start (the number of iterations: {rebidcnt}), after which the contributor selection will follow.',
    and: ' and ',
    wagerate: 'Hourly rate w/o VAT',
    laborCostsW: 'Exp. efforts, h',
    laborCostsF: 'Act. efforts, h',
    payfact: 'Approving Labor Costs',
    factval: 'Act. cost',
    factvalInfoExec1: 'Actual efforts were ',
    factvalInfoExec2: '0 hours | {n} hour | {n} hours | {n} hours',
    factvalInfoExec3Eq: ', which corresponds to the expected efforts. ',
    factvalInfoExec3Gt: ', which is {0}% higher than expected efforts. ',
    factvalInfoExec3Lt: ', which is {0}% lower than expected efforts. ',
    factvalInfoExec4: "The actual labor costs were {0} {1} without VAT with the contributor's horly rate {2} {1}.",
    factvalAppWait: 'Pending approval by the following responsible: ',
    factvalBefore: 'The request has been completed. The contributor is preparing a report on the actual labor costs.',
    payfactCorrect: 'Adjusting Labor Costs',
    tradeOffer: 'Submitting a proposal',
    platformCommentReturnFromTrade: 'The request was returned from the Contributor Selection stage due to the lack of proposals from bidders. You can resume bidding after adjusting the requirements and terms of the request.',
    returnFromTrade: 'Return from the Contributor Selection',
    reprequestlist: 'Requests Report',
    repTender: 'Contributor Selection Report',
    curLaborCosts: 'Actual efforts',
    repRequest: 'Requests Report',
    share: 'Share',
    copyText: 'Link to this request:'
  },
  event: {
    start: 'Start',
    coordagree: 'Approval by a product owner / coordinator',
    trade: 'Bidding',
    requirements: 'Clarification of the requirements',
    rebid: 'Rebidding',
    initwinner: 'Selecting a contributor',
    coordwinner: 'Approving the selected contributor',
    startconf: 'Confirming the start of work',
    stanterm: 'Implementation',
    accepttest: 'Testing',
    deploy: 'Architectural assurance / Deployment',
    payfact: 'Approving the actual labor costs',
    innerexec: 'Assigning an in-house сontributor'
  },
  act: {
    add: 'Create New PBI / Request',
    decline: 'Reject',
    copy: 'Duplicate',
    additional: 'More',
    conform: 'Approve',
    accept: 'Accept',
    confirm: 'Confirm',
    beginWork: 'Proceed',
    actualize: 'Update Status',
    acceptanceTest: 'Testing',
    deployment: 'Architectural Assurance / Deployment',
    complete: 'Complete',
    refuse: 'Reject',
    test: 'Proceed to Testing',
    stop: 'Terminate',
    defectsElim: 'Resolve Issues',
    chooseInner: 'Assign an In-house Contributor',
    choose: 'Select',
    chooseExec: 'Select the Winner',
    estimate: 'Rate',
    stopTrade: 'Stop Bidding',
    stopRebid: 'Stop Rebidding',
    acceptOffer: 'Accept Proposal',
    viewOffer: 'View Proposal',
    showAllOffers: 'Show All Proposals',
    expand: 'Expand requests',
    collapse: 'Collapse requests',
    assign: 'Assign',
    editRequest: 'Change Request',
    toWork: 'Proceed',
    correct: 'Edit Change Request',
    cancel: 'Cancel Request',
    startRebid: 'Proceed to Rebidding',
    proceed: 'Proceed',
    // Используется в диалоговом окошке с запросом, нужно ли уточнять требования к заявке перед переторжкой
    toExcel: 'Export to Excel',
    paidCustomer: 'Paid by the client',
    settlements: 'Mutual payments completed',
    makeReport: 'Generate the report',
    repRequest: 'Requests Report'
  },
  activity: {
    needAct: 'Need Action',
    wait: 'Pending',
    work: 'In Progress',
    history: 'History'
  },
  period: {
    week: 'Week',
    month: 'Month',
    year: 'Year'
  },
  msg: {
    tableRebiddingParticipants: 'Participants of the previous round of bidding',
    tableRebiddingLaborCostsH: 'Labor costs, h',
    tableRebiddingBidPrice: 'Bid price of the previous round of trading, rub',
    tableRebiddingMemberNo: 'Member № ',
    tableRebiddingYourSuggestion: '(Your suggestion)',
    confirmDecline: 'Reject the request?',
    confirmAccept: 'Accept the request?',
    confirmEstimate: 'Estimate the request?',
    needVal: 'The price should be defined',
    needLaborCost: 'The efforts should be defined',
    needDiscount: 'The discount should be defined',
    needAltTerm: 'An alternative deadline should be defined if the discount is indicated',
    laborRebid: 'The efforts in the improved proposal cannot exceed the efforts in the original proposal',
    dateRebid: 'The suggested deadline in the improved proposal cannot be later than the deadline in the original proposal',
    minRate: 'No accredited contributors meeting the minimum rating requirement',
    altTerm: 'The suggested deadline shuld be later than the requested one',
    altervalRebid: 'The price of the improved alternative proposal cannot be higher than the price of the original alternative proposal',
    valService: 'The price of the improved proposal cannot be higher than the price of the original proposal',
    needTerm: 'The deadline should be defined',
    needExpected: 'The expected deadline should be defined',
    needExpectedAfterNow: 'The expected deadline cannot be earlier than the current date',
    needDescription: 'The description is required',
    needComment: 'The comments are required',
    tradeStopped: 'Bidding stopped',
    rebidStopped: 'Rebidding stopped',
    wrongTerm: 'Please check if the deadline is correct',
    alterComment: 'Please add the comments on selecting the alternative contributor',
    alterInitComment: 'Please add the comments on selecting the alternative proposal',
    actEditRequestConfirm: 'Are you sure you want to change the request during its implementation?',
    needOfferVal: 'Please define the new price of the request',
    actEditRequestExecDecline: 'Are you sure you want to reject the change request? In this case, the implementation will continue, but the request can be completely canceled by either party.',
    actCancelRequestConfirm: 'Are you sure you want to completely cancel the request during its implementation?',
    needOfferVal4Cancel: 'Please define the payment amount',
    actCancelRequestExecDecline: 'Are you sure you want to reject the cancellation request? In this case, payment for the work performed according to the proposed conditions will not be carried out',
    needComment4Exec: 'Please add the comments for the contributor',
    platformCommentActualizeStatus: 'Work in progress: {0}%, actual efforts {2} h, the expected deadline: {1:dt}.',
    // The text in brackets should not be translated!
    needFactEfforts: 'Please indicate the actual labor costs for the request for approval by the client',
    wrongDateFormat: 'Please check if the date and time are correct',
    needHourlyRate: 'Please indicate the hourly rate',
    needAgreement: 'Please conclude the required agreements in order to participate in the bidding',
    cancelAccepted: 'Request cancellation confirmed',
    cancelDeclined: 'Request cancellation rejected',
    toExcel: 'Only those requests will be included to the Microsoft Excel file that are currently shown in the PBIs & Requests section. Please make sure that all unnecessary filters are removed.'
  }
};