export default {
  // A contributor's board
  lab: {
    newRequests: 'Neue Anträge',
    pending: 'Ausstehend',
    rejected: 'Abgelehnt',
    todo: 'Zu erledigen',
    inProgress: 'In Bearbeitung',
    done: 'Erledigt',
    cancelled: 'Abgebrochen',
    accept: 'Annehmen',
    estimate: 'Voranschlag',
    reject: 'Ablehnen',
    update: 'Aktualisieren',
    rate: 'Klienten bewerten'
  },
  format: {
    formatIntervalWithDays: 'DDd HH:MM',
    formatIntervalWODays: 'HH:MM:SS'
  }
};