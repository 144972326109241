import "core-js/modules/es6.array.slice.js";
import "core-js/modules/es6.object.to-string.js";
import "core-js/modules/es6.regexp.to-string.js";
export function suExamURL(route) {
  if (!route.query.sid) {
    return true;
  }
  var i = route.fullPath.indexOf('&hash=');
  return i > 0 && _hashFnv32a(route.fullPath.slice(0, i)) == route.fullPath.slice(i + 6);
}
export function suGetSignupURL(sid, cur) {
  var url = '/signup?sid=' + String(sid) + '&cur=' + String(cur);
  return url + '&hash=' + _hashFnv32a(url);
}
export function suGetLoginURL(sid, cur) {
  var url = '/login?sid=' + String(sid) + '&cur=' + String(cur);
  return url + '&hash=' + _hashFnv32a(url);
}
export function suGetServiceURL(sid, cur) {
  return '/service?id=' + String(sid) + '&cur=' + String(cur);
}

// ----------------------------------------------------------------------------
//                               _hashFnv32a
//
//  * Calculate a 32 bit FNV-1a hash
//  * Found here: https://gist.github.com/vaiorabbit/5657561
//  * Ref.: http://isthe.com/chongo/tech/comp/fnv/
// ----------------------------------------------------------------------------
function _hashFnv32a(str) {
  var i,
    l,
    hval = 0x811c9dc5;
  for (i = 0, l = str.length; i < l; i++) {
    hval ^= str.charCodeAt(i);
    hval += (hval << 1) + (hval << 4) + (hval << 7) + (hval << 8) + (hval << 24);
  }
  // Convert to 8 digit hex string
  return ('0000000' + (hval >>> 0).toString(16)).substr(-8);
}