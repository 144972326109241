export var references = {
  msg: {
    accessRefHeader: 'Управление доступом',
    accessRefSubheader: 'Справочники пользователей и ролей',
    sysRefHeader: 'Системные настройки',
    sysRefSubheader: 'Общесистемные справочники',
    ordinalRefHeader: 'Заказчики и исполнители',
    ordinalRefSubheader: 'Основные данные заказчиков и исполнителей',
    iframeRefHeader: 'Аналитика',
    iframeRefSubheader: 'Настройка отчётности для пользователей',
    integrRefHeader: 'Настройка интеграции с АСУ НСИ',
    integrServName: 'Услуга для привязки заявок АСУ НСИ'
  }
};