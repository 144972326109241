export var smaAnalytics = {
  reportType: 'Тип отчета',
  btnLoadReport: 'Выгрузить в Excel',
  btnLoadStatistic: 'Сформировать',
  filenameRep1: 'Отчет по количеству заявок',
  filenameRep2: 'Подробный отчет по заявкам',
  filenameRep3: 'Отчет по исполнителям',
  filenameRep4: 'Отчет по экономическим показателям',
  captionProduct: 'Продукт',
  captionExecutor: 'Исполнитель',
  captionPeriod: 'Период',
  captionRating: 'Рейтинг',
  errorEmptyParameter: 'Заполните все параметры отчета',
  errorDtGeLe: 'Дата начала должна быть меньше даты окончания',
  errorDtGeMin: 'Дата начала слишком мала',
  errorRatingGeLe: 'Рейтинг минимальный должен быть меньше рейтинга максимального'
};