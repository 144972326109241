export default {
  lab: {
    object: "Настройка роли доступа",
    cardTab: "Настройка роли доступа",
    type: "Вид роли",
    objcode: "Код бизнес-объекта",
    objcaption: "Наименование бизнес-объекта",
    permlab: "Вид доступа",
    actperm: "Установить доступ: ",
    permR: "Только просмотр",
    permW: "Модификация",
    permA: "Администратор",
    permN: "Нет доступа"
  },
  act: {},
  msg: {
    permdone: "Доступ установлен"
  }
};