export default {
  // Administrating user roles
  lab: {
    object: "Konfigurieren der Zugriffsrolle",
    cardTab: "Konfigurieren der Zugriffsrolle",
    type: "Art der Rolle",
    objcode: "Code des Geschäftsobjekts",
    objcaption: "Name des Geschäftsobjekts",
    permlab: "Art des Zugriffs",
    actperm: "Um den Zugriff einzustellen: ",
    permR: "Ansehen",
    permW: "Bearbeiten",
    permA: "Administrator",
    permN: "Kein Zugriff"
  },
  act: {},
  msg: {
    permdone: "Zugriff ist festgelegt"
  }
};