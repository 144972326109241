export default {
  // ADMINISTRATOR mode, administrating users
  lab: {
    object: 'Benutzer',
    cardRec: 'Benutzer',
    cardRse: 'Benutzer Filtern',
    cardTab: 'Benutzer',
    login: 'Benutzerkonto',
    name: 'Nachname Vorname',
    jobposition: 'Position',
    phone: 'Telefon',
    email: 'E-mail',
    usertype: 'Benutzertyp',
    type: 'Typ',
    exekind: 'Mitwirkender Typ',
    executor: 'Mitwirkender',
    customer: 'Klient',
    smart: 'Plattform Mitarbeiter',
    customerfirm: 'Firma',
    kindP: 'Person',
    kindF: 'Firma',
    kindG: 'Virtuelles Team',
    kindPt: 'Person',
    kindFt: 'Firma',
    kindGt: 'Team',
    ava: 'Photo'
  },
  act: {
    actResetPsw: 'Passwort zurücksetzen'
  },
  msg: {
    confirmResetPsw: 'Sind Sie sicher, dass Sie das Passwort zurücksetzen möchten?',
    resetdone: 'Das Passwort wurde erfolgreich zurückgesetzt'
  }
};