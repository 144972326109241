export var aftAvatar = {
  // ADMINISTRATOR mode, administrating languages, currencies, etc.
  tooBigAvatar: 'The picture is too large. Please select another file',
  avatarInvalidExtension: 'Only files with the following extensions are allowed: jpg, jpeg, png'
};
export var aftLogo = {
  tooBigLogo: 'The picture is too large. Please select another file',
  logoInvalidExtension: 'Only files with the following extensions are allowed: jpg, jpeg, png'
};
export var smaCurrency = {
  lab: {
    rec: '',
    tab: 'Currency'
  },
  label: {
    tabCaption: 'Currency',
    name: 'Name',
    code: 'Code',
    symbol: 'Symbol',
    id: 'XXXXXXXXXXXXID',
    nameLoc: 'XXXXXXXXXXXXXName localization code'
  }
};
export var comLang = {
  lab: {
    rec: '',
    tab: 'Language'
  },
  label: {
    tabCaption: 'Language',
    name: 'Name',
    code: 'Code',
    active: 'Active',
    localeCode: 'Regional code',
    defShortDateFmt: 'Date format',
    defLongDateFmt: 'Date and time format'
  }
};
export var dashboard = {
  label: {
    tabCaption: 'Analytics Users'
  },
  lab: {
    name: 'Analytics User',
    link: 'URL',
    dashboardSave: 'Save'
  }
};
export var analytics = {
  lab: {
    tab: 'Analytics'
  }
};