export default {
  lab: {
    object: 'Пользователи',
    cardRec: 'Пользователь',
    cardRse: 'Фильтр пользователей',
    cardTab: 'Пользователи',
    login: 'Учётная запись',
    name: 'Фамилия Имя',
    jobposition: 'Должность',
    phone: 'Телефон',
    email: 'E-mail',
    usertype: 'Тип пользователя',
    type: 'Тип',
    exekind: 'Тип исполнителя',
    executor: 'Исполнитель',
    customer: 'Заказчик',
    smart: 'Сотрудник платформы',
    customerfirm: 'Компания',
    kindP: 'Физическое лицо',
    kindF: 'Юридическое лицо',
    kindG: 'Виртуальня команда',
    kindPt: 'Физлицо',
    kindFt: 'Юрлицо',
    kindGt: 'Команда',
    ava: 'Фотография'
  },
  act: {
    actResetPsw: 'Сбросить пароль'
  },
  msg: {
    confirmResetPsw: 'Вы уверены, что хотите сбросить пароль?',
    resetdone: 'Пароль сброшен'
  }
};