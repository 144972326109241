export var smaCalendar = {
  // ADMINISTRATOR mode, administrating base calendars
  lab: {
    cardRec: 'Calendar',
    cardTab: 'Base Calendar'
  },
  act: {
    actFillWorkDays: 'Recalculate'
  },
  label: {
    tabCaption: 'Base Calendar',
    name: 'Calendar',
    gmtPlus: 'Time Zone (UTC+)',
    hourStart: 'Start',
    hourEnd: 'Finish',
    hourStartGreatestHourEnd: 'Start should be earlier than Finish',
    hourInWorkDayTooBig: 'The number of working hours is too high for the interval specified',
    hoursInWorkDay: 'Working hours a day',
    actFillWorkDaysDateFrom: 'Date from',
    actFillWorkDaysDateTo: 'Date to',
    actFillWorkDaysSelectHoliday: 'Select weekends:',
    actFillWorkDaysSaturdayIsHoliday: 'Saturday',
    actFillWorkDaysSundayIsHoliday: 'Sunday',
    actFillWorkDaysResultOk: 'Recalculation completed successfully',
    actFillWorkDaysResultError: 'Recalculation failed'
  }
};
export var smaCalendarDay = {
  lab: {
    cardRec: 'Calendar Day',
    cardTab: 'Base Calendar Day'
  },
  label: {
    tabCaption: 'Base Calendar Day',
    calName: 'Calendar name',
    dt: 'Date',
    startTime: 'Start',
    endTime: 'Finish',
    isWorkDay: 'Working day',
    weekDay: 'Day of week',
    holiday: 'Weekend'
  },
  msg: {
    actChangeWorkFlagResultSuccess: 'The working day attribute successfully changed',
    actChangeWorkFlagResultError: 'Changing the working day attribute failed',
    checkEndTime: 'Finish should be later than Start'
  }
};