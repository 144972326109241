export default {
  lab: {
    rec: 'Заявка',
    name: 'Наименование',
    note: 'Комментарий',
    num: 'Номер',
    addreqcap: 'Новая заявка',
    servname: 'Продукт / Услуга',
    description: 'Описание',
    docs: 'Документы',
    yourdocs: 'Ваши документы',
    customerdocs: 'Документы заказчика',
    start: 'Старт',
    term: 'Срок исполнения',
    minTerm: 'Мин. срок исполнения',
    termForCustomer: 'Ожидаемый срок',
    val: 'Стоимость',
    accordion1: 'Согласование заявки',
    accordion2: 'Реализация',
    accordion3: 'Тестирование',
    accordion4: 'Архитектурный контроль / Развёртывание',
    accorditionIniwin: 'Выбор исполнителя',
    accorditionTop5: 'Топ-5 исполнителей',
    stages: 'Сроки по этапам',
    event: 'Этап',
    eventdtstart: 'Начало',
    eventdt: 'Окончание',
    stname: 'Статус',
    state: 'Состояние',
    customer_name: 'Заказчик',
    executor_name: 'Исполнитель',
    initiator_name: 'Инициатор',
    responsible: 'Ответственный',
    expected: 'Ожид. срок',
    expectedfull: 'Ожидаемый срок',
    period: 'Период',
    dt: 'Дата создания',
    tillTrade: 'До окончания торгов: ',
    tillInnerExec: 'До окончания назначения внутреннего исполнителя: ',
    tillChoose: 'До окончания выбора победителя торгов: ',
    promptDecline: 'Причина отклонения',
    promptComment: 'Комментарий (необязательно)',
    promptAlter: 'Причина выбора альтернативного победителя',
    saveMsg: 'Сохранение...',
    mainOffer: 'Основное предложение',
    laborCosts: 'Трудозатраты',
    hour: 'час',
    altOffer: 'Альтернативное предложение',
    proposedTerm: 'Предлагаемый срок',
    propTerm: 'Предложенный срок',
    discount: 'Скидка',
    noteExec: 'Комментарий исполнителя',
    actExecAcceptSuccess: 'Заявка принята',
    exec: 'Исполнение',
    usePower: 'Воспользуйтесь возможностью уточнить своё предложение в рамках переторжки!',
    costDeviationGT: 'Предложенные Вами трудозатраты на {devCost}% выше среднего предложения',
    costDeviationLT: 'По результатам торгов Ваши трудозатраты были среди лучших! Обращаем внимание, что на переторжке другие исполнители могут улучшить свои предложения.',
    // ПЛАТФОРМА НЕ ДОЛЖНА СООБЩАТЬ НА СКОЛЬКО % ПРЕДЛОЖЕНИЕ НИЖЕ СРЕДНЕГО! (КРОМЕ СЛУЧАЕВ, КОГДА ИДУТ ТОРГИ НА УВЕЛИЧЕНИЕ)

    costDeviationEQ: 'Предложенные Вами трудозатраты соответствуют среднему предложению',
    termDeviationGT: 'Предложенные Вами сроки на {devTerm}% дольше среднего предложения',
    termDeviationLT: 'Предложенные Вами сроки на {devTerm}% лучше среднего предложения',
    termDeviationEQ: 'Предложенные Вами сроки соответствуют среднему предложению',
    termDeviationNC: 'По результатам торгов Ваши трудозатраты были среди лучших! Обращаем внимание, что',
    termDeviationIA: 'на переторжке другие исполнители могут улучшить свои предложения.',
    // ПЛАТФОРМА НЕ ДОЛЖНА СРАВНИВАТЬ ПРЕДЛАГАЕМЫЕ СРОКИ

    medalFirst1: 'Поздравляем! Ваше ',
    medalFirst2: 'альтернативное ',
    medalFirst3: 'предложение принято.',
    medalFirst4: 'Вы назначены исполнителем по заявке.',
    medalSecond: 'Просим Вас подтвердить начало работ по заявке.',
    winnerSecond1: 'Мы рекомендуем выбрать ',
    winnerSecond2: 'основное ',
    winnerSecond3: 'альтернативное ',
    winnerSecond4: 'предложение.',
    winnerThird: '{winName} выполняет работу в срок в {winProc}% случаев.',
    hcolSt1: 'Статус до',
    hcolSt2: 'Статус после',
    hcolReason: 'Причина отклонения',
    hcolComment: 'Комментарий',
    chooseExecutor: 'Выберите исполнителя',
    tradeWinner: 'Победитель торгов',
    otherExecs: 'Другие участники торгов',
    initChosen: 'Исполнитель, выбранный инициатором',
    execRecom: 'Рекомендуем исполнителя',
    testingCussessful: 'Тестирование успешно',
    testingFailed: 'Тестирование неуспешно',
    deployCussessful: 'Архитектурный контроль / развёртывание успешно',
    deployFailed: 'Архитектурный контроль / развёртывание неуспешно',
    execChosen: 'Выбран исполнитель',
    valDeltaGT: 'Предложение на {valDelta}% дороже ',
    valDeltaLT: 'Предложение на {valDelta}% дешевле ',
    valDeltaEQ: 'Предложение соответствует ',
    platformRecom0: 'рекомендованому платформой',
    platformRecom: 'рекомендованого платформой',
    daysDelta0: 'Срок соответствует первоначальному сроку',
    daysDelta: 'Срок на {daysDelta} рабочих дней превышает первоначальный',
    smartChoose: 'Выбор платформы',
    innerChoose: 'Назначение внутреннего исполнителя',
    reqInWork: 'Заявок в работе',
    free: 'Свободен',
    busy: 'Занят',
    overloaded: 'Перегружен',
    platformName: 'Платформа Smart IT',
    tenders: '0 поданных предложений | {n} поданного предложения | {n} поданных предложений | {n} поданных предложений',
    cntRebids: '0 переторжек. | {n} переторжки. | {n} переторжек. | {n} переторжек.',
    platformChooseText: 'Победитель выбран на основе критериев цена ({valScale}%), качество ({qualScale}%), минимальный рейтинг ({minrate}) среди ',
    platformChooseText1: ' по результатам торгов и ',
    coordChoose: 'Выбор исполнителя согласован',
    initChooseLongDesc1: 'Исполнителем выбран ',
    initChooseLongRating: '(рейтинг {valRate})',
    initChooseLongDesc2: ' с ',
    initChooseLongDesc3Main: ' основным ',
    initChooseLongDesc3Alter: ' альтернативным ',
    initChooseLongDesc4: ' предложением стоимостью ',
    initChooseLongDesc5: ' и ',
    initChooseLongDesc6Main: ' запрашиваемым ',
    initChooseLongDesc6Alter: ' предложенным ',
    initChooseLongDesc7: ' сроком исполнения ',
    platformChooseLongDesc1: 'Данное предложение отличается от рекомендуемого платформой Smart IT: исполнитель ',
    platformChooseLongDesc2: ' с ',
    chosenOffer: 'Выбранное предложение',
    initOfferChosen: 'Выбор инициатора',
    innerChooseLongDesc1: 'Исполнителем назначен ',
    // ИЛИ выбран? ВНУТРЕННИЙ ИЛИ ВНЕШНИЙ?
    innerChooseLongDesc2: '',
    offerNewValue: 'Предложить новую стоимость:',
    withoutChangeCost: 'Без изменения стоимости заявки: ',
    innerComment: 'Внутренний комментарий',
    comment4Exec: 'Комментарий для исполнителя',
    changeReq: 'Изменение заявки',
    changeReqReq: 'Запрос на изменение заявки',
    changeReqReqConf: 'Запрос на изменение заявки подтвержден',
    changeReqReqRej: 'Запрос на изменение заявки отклонен',
    newReqValue: 'Предложенная сумма оплаты: ',
    newReqValueProc: ', что составляет {proc}% от стоимости заявки.',
    comment4Init: 'Комментарий для заказчика',
    cancelReq: 'Отмена заявки',
    offerAmount: 'Предложите размер оплаты заявки:',
    fullRequestCost: 'Полная стоимость заявки: ',
    newCostValue: 'Укажите сумму оплаты: ',
    recommendVal: '{recProc}% от стоимости заявки: ',
    recommend: '(рекомендуется)',
    spendedVal: 'Пропорционально затраченному времени ({spendProc}%): ',
    changeReqCan: 'Запрос на отмену заявки',
    cancelReqInfo1: ' Это является ',
    cancelReqInfo2: 'стандартным условием ',
    cancelReqInfo3: 'для отмены заявки на данном этапе.',
    cancelReqReqConf: 'Запрос на отмену заявки подтвержден',
    cancelReqReqRej: 'Запрос на отмену заявки отклонен',
    leaveComment: 'Оставьте Ваш комментарий',
    reqAgree: 'Заявка согласована',
    reqAgreeToCoord: 'Заявка отправлена на согласование ВП / координатору',
    reqCancel: 'Заявка отклонена',
    indPeriod: 'Укажите срок, в который Вы сможете выполнить заявку дешевле',
    actualizing: 'Актуализация статуса',
    sendToTest: 'Передано в тестирование',
    sendToDeploy: 'Передано на архитектурный контроль / развёртывание',
    sendToComplete: 'Завершено',
    makingRequest: 'Создание заявки',
    editingRequest: 'Редактирование заявки',
    smartWin: 'Выбор исполнителя в автоматическом режиме',
    rating: 'Рейтинг',
    initRequest: 'Создание заявки',
    startWorkConfirm: 'Подтверждение старта работ',
    offerDeclined: 'Не подтверждён старт работ',
    offerDeclinedTxt: 'Исполнитель не подтвердил старт начала работ в регламентные сроки',
    notActual: 'Не актуально',
    tradeInfo1: 'Заявка находится на этапе {stcode}{numreb}. До окончания {stcode} осталось ',
    tradeInfo1Bid: 'торгов',
    tradeInfo1Rebid: 'переторжки',
    tradeInfo2: '{cur} свои предложения подали {alltenders} из {allexecs}, включая {allinner} и {allouter}. Количество предложений со сроком выполнения, соответствующим запрошенному: {allactual}.',
    tradeInfo2CurMoment: 'На текущий момент ',
    tradeInfo2CurResult: 'По результатам торгов',
    tradeInfo2CurResult2: 'По результатам торгов и переторжек',
    tradeInfo2AllExecs: '0 аккредитованных исполнителей | {n} аккредитованного исполнителя | {n} аккредитованных исполнителей | {n} аккредитованных исполнителей',
    tradeInfo2AllInner: '0 внутренних | {n} внутреннего | {n} внутренних | {n} внутренних',
    tradeInfo2AllOuter: '0 внешних исполнителей | {n} внешнего исполнителя | {n} внешних исполнителей | {n} внешних исполнителей',
    tradeInfo3: 'От подачи предложения {allrej}, включая {allinnerrej} и {allouterrej}.',
    tradeInfo3Execs: 'отказалось 0 исполнителей | отказался {n} исполнитель | отказались {n} исполнителя | отказались {n} исполнителей',
    tradeInfo4Req: 'По окончании торгов заявка перейдет на этап "Уточнение требований", после чего последует переторжка.',
    tradeInfo4BidRebid: 'По окончании торгов заявка перейдет на этап "Переторжка" (количество итераций: {rebidcnt}), после чего последует выбор исполнителя.',
    tradeInfo4Exec: 'По окончании торгов заявка перейдет на этап "Выбор исполнителя".',
    tradeInfo4Rebid: 'По окончании переторжки (количество итераций: {rebidcnt}) заявка перейдет на этап "Выбор исполнителя".',
    tradeInfo5Res: 'В ходе переторжек ',
    tradeInfo5Execs: '0 исполнителей улучшили | {n} исполнитель улучшил | {n} исполнителя улучшили | {n} исполнителей улучшили ',
    tradeInfo5ExecsDown: '0 исполнителей ухудшили | {n} исполнитель ухудшил | {n} исполнителя ухудшили | {n} исполнителей ухудшили ',
    tradeInfo5Con: ' свое предложение по сравнению с торгами. В среднем, стоимость основного предложения {avgrebid}%, максимальное снижение составило {maxrebid}%.',
    tradeInfo5ConLT: 'снизилась на ',
    tradeInfo5ConGT: 'увеличилась на ',
    endOfBid: 'Торги завершены',
    endOfRebid: 'Торги и переторжки завершены',
    tradeInfoReq2: 'Заявка находится на этапе уточнения требований.',
    tradeInfoReqWLink31: 'Просим Вас провести встречи с ',
    tradeInfoReqWLink32: 'участниками торгов',
    tradeInfoReqWLink33: ' для презентации ими текущих предложений и переговоров.',
    tradeInfoReq5: 'Для обеспечения равных условий и улучшения конкурентной среды Вы можете провести одну общую встречу со всеми участниками торгов для уточнения требований. Это позволит им получить равный доступ к информации, включая ответы на вопросы других исполнителей.',
    isClarificationNeed: 'Требуется ли уточнение требований к заявке?',
    stateRequrements: 'Уточнение требований к заявке',
    tradeInfoReqRebid: 'По окончании данного этапа заявка перейдет на этап "Переторжка" (количество итераций: {rebidcnt}), после чего последует выбор исполнителя.',
    and: ' и ',
    wagerate: 'Ставка в час без НДС',
    laborCostsW: 'Ожид. трудозатраты, ч',
    laborCostsF: 'Факт. трудозатраты, ч',
    payfact: 'Согласование трудозатрат',
    factval: 'Факт. стоимость',
    factvalInfoExec1: 'Фактические трудозатраты составили ',
    factvalInfoExec2: '0 часов | {n} час | {n} часа | {n} часов',
    factvalInfoExec3Eq: ', что соответствует ожидаемым трудозатратам. ',
    factvalInfoExec3Gt: ', что превышает ожидаемые трудозатраты на {0}%. ',
    factvalInfoExec3Lt: ', что ниже ожидаемых трудозатратат на {0}%. ',
    factvalInfoExec4: 'Фактическая стоимость составила {0} {1} без НДС с учётом ставки исполнителя {2} {1} в час.',
    factvalAppWait: 'Ожидается согласование следующими ответственными сотрудниками: ',
    effortsConform: 'Трудозатраты согласованы',
    effortsDecline: 'Трудозатраты не согласованы',
    factvalBefore: 'Работы по заявке завершены. Исполнитель формирует отчёт по фактическим трудозатратам.',
    payfactCorrect: 'Уточнение трудозатрат',
    tradeOffer: 'Подача предложения',
    platformCommentReturnFromTrade: 'Заявка возвращена с этапа "Выбор исполнителя" из-за отсутствия предложений потенциальных исполнителей по результатам торгов. Вы можете вновь запустить торги, уточнив требования и сроки выполнения заявки.',
    returnFromTrade: 'Возврат с этапа "Выбор исполнителя"',
    reprequestlist: 'Отчёт по заявкам',
    repTender: 'Отчёт о выборе исполнителя',
    curLaborCosts: 'Текущие трудозатраты',
    repRequest: 'Отчёт по заявкам',
    share: 'Поделиться',
    copyText: 'Ссылка на эту заявку:'
  },
  event: {
    start: 'Старт',
    coordagree: 'Согласование заявки владельцем продукта / координатором',
    trade: 'Торги',
    requirements: 'Уточнение требований',
    rebid: 'Переторжка',
    initwinner: 'Выбор исполнителя',
    coordwinner: 'Согласование выбора исполнителя',
    startconf: 'Подтверждение старта работ',
    stanterm: 'Реализация',
    accepttest: 'Тестирование',
    deploy: 'Архитектурный контроль / развёртывание',
    payfact: 'Согласование фактических трудозатрат',
    innerexec: 'Назначение внутреннего исполнителя'
  },
  act: {
    add: 'Создать новую заявку',
    decline: 'Отклонить',
    copy: 'Дублировать',
    additional: 'Дополнительно',
    conform: 'Согласовать',
    accept: 'Принять',
    confirm: 'Подтвердить',
    beginWork: 'Приступить',
    actualize: 'Актуализировать статус',
    acceptanceTest: 'Тестирование',
    deployment: 'Архитектурный контроль / развёртывание',
    complete: 'Завершить',
    refuse: 'Отказаться',
    test: 'В тестирование',
    stop: 'Прекратить выполнение',
    defectsElim: 'На доработку',
    chooseInner: 'Назначить внутреннего исполнителя',
    choose: 'Выбрать',
    chooseExec: 'Выбрать победителя торгов',
    estimate: 'Оценить',
    stopTrade: 'Остановить торги',
    stopRebid: 'Остановить переторжку',
    acceptOffer: 'Принять предложение',
    viewOffer: 'Просмотреть',
    showAllOffers: 'Показать все предложения',
    expand: 'Развернуть заявки',
    collapse: 'Свернуть заявки',
    assign: 'Назначить',
    editRequest: 'Изменить заявку',
    toWork: 'В работу',
    correct: 'Скорректировать',
    cancel: 'Отменить заявку',
    startRebid: 'Приступить к переторжке',
    proceed: 'Продолжить',
    toExcel: 'Выгрузить в Excel',
    paidCustomer: 'Оплачено Заказчиком',
    settlements: 'Взаиморасчёты выполнены',
    makeReport: 'Сформировать отчёт',
    repRequest: 'Отчёт по заявкам'
  },
  activity: {
    needAct: 'Требуется активность',
    wait: 'Ожидание',
    work: 'В работе',
    history: 'История'
  },
  period: {
    week: 'За неделю',
    month: 'За месяц',
    year: 'За год'
  },
  msg: {
    tableRebiddingParticipants: 'Участники предыдущего раунда торгов',
    tableRebiddingLaborCostsH: 'Трудозатраты, ч',
    tableRebiddingBidPrice: 'Цена заявки предыдущего раунда торгов, руб',
    tableRebiddingMemberNo: 'Участник №',
    tableRebiddingYourSuggestion: '(Ваше предложение)',
    confirmDecline: 'Отклонить заявку?',
    confirmAccept: 'Принять заявку?',
    confirmEstimate: 'Оценить заявку?',
    needVal: 'Необходимо указать стоимость',
    needLaborCost: 'Необходимо указать трудозатраты',
    needDiscount: 'Необходимо указать размер скидки',
    needAltTerm: 'Альтернативный срок должен быть указан, если указан размер скидки',
    laborRebid: 'Трудозатраты улучшенного предложения не могут быть выше трудозатрат первоначального предложения',
    dateRebid: 'Предлагаемый срок улучшенного предложения не может быть позднее предлагаемого срока первоначального предложения',
    minRate: 'Нет аккредитованных исполнителей, удовлетворяющих требованию к минимальному рейтингу',
    altTerm: 'Предлагаемый срок должен быть позже запрашиваемого срока исполнения',
    altervalRebid: 'Стоимость улучшенного альтернативного предложения не может быть выше стоимости первоначального альтернативного предложения',
    valService: 'Стоимость улучшенного предложения не может быть выше стоимости первоначального предложения',
    needTerm: 'Не указан срок исполнения',
    needExpected: 'Не указан ожидаемый срок',
    needExpectedAfterNow: 'Ожидаемый срок не может быть ранее текущей даты',
    needDescription: 'Описание является обязательным',
    needComment: 'Комментарий является обязательным',
    tradeStopped: 'Торги остановлены',
    rebidStopped: 'Переторжка остановлена',
    wrongTerm: 'Убедитесь, пожалуйста, что срок исполнения укладывается в регламентные сроки всех этапов',
    alterComment: 'Пожалуйста, добавьте комментарий по выбору альтернативного исполнителя',
    alterInitComment: 'Пожалуйста, добавьте комментарий по выбору альтернативного предложения',
    actEditRequestConfirm: 'Вы уверены, что хотите изменить заявку в ходе ее выполнения?',
    needOfferVal: 'Пожалуйста, укажите новую стоимость заявки',
    actEditRequestExecDecline: 'Вы уверены, что хотите отклонить предложение по изменению заявки? В этом случае реализация продолжится, но заявка может быть полностью отменена по инициативе любой из сторон',
    actCancelRequestConfirm: 'Вы уверены, что хотите полностью отменить заявку в ходе ее выполнения?',
    needOfferVal4Cancel: 'Пожалуйста, укажите сумму оплаты',
    actCancelRequestExecDecline: 'Вы уверены, что хотите отклонить предложение по отмене заявки? В этом случае, оплата выполненных работ согласно предложенным условиям не будет проведена',
    needComment4Exec: 'Пожалуйста, добавьте комментарий для исполнителя',
    platformCommentActualizeStatus: 'Исполнение: {0}%, текущие трудозатраты {2} ч., ожидаемый срок: {1:dt}.',
    // Комментарий платформы по актуализации статуса заявки. Текст внутри фигурных скобок - системный, не менять!
    needFactEfforts: 'Пожалуйста, укажите фактические трудозатраты для согласования заказчиком',
    wrongDateFormat: 'Пожалуйста, проверьте формат введёных даты и времени',
    needHourlyRate: 'Пожалуйста, укажите Вашу ставку',
    needAgreement: 'Пожалуйста, подпишите необходимые соглашения для того, чтобы участвовать в торгах',
    cancelAccepted: 'Отмена заявки подтверждена',
    cancelDeclined: 'Отмена заявки отклонена',
    toExcel: 'В файл Microsoft Excel будут выгружены только те заявки, которые в настоящий момент отражены в разделе "Заявки". Пожалуйста, убедитесь, что все лишние фильтры убраны.'
  }
};