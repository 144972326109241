export var smaAnalytics = {
  reportType: 'Berichtstyp',
  btnLoadReport: 'Nach Excel hochladen',
  btnLoadStatistic: 'Bilden',
  filenameRep1: 'Bericht über die Anzahl der Bewerbungen',
  filenameRep2: 'Detaillierten Bericht über Anfragen',
  filenameRep3: 'Bericht über Darsteller',
  filenameRep4: 'Bericht über Wirtschaftsindikatoren',
  captionProduct: 'Produkt',
  captionExecutor: 'Testamentsvollstrecker',
  captionPeriod: 'Zeitraum',
  captionRating: 'Bewertung',
  errorEmptyParameter: 'Füllen Sie alle Berichtsparameter aus',
  errorDtGeLe: 'Startdatum zu groß',
  errorDtGeMin: 'Startdatum zu klein',
  errorRatingGeLe: 'Die Mindestbewertung muss kleiner sein als die Höchstbewertung'
};