export default {
  lab: {
    form: 'Пожалуйста, авторизуйтесь',
    frmchngpsw: 'Смена пароля',
    login: 'Учётная запись',
    psw: 'Пароль',
    welcome: 'Ещё нет учётной записи на платформе?',
    newpsw: 'Новый пароль',
    oldpsw: 'Текущий пароль',
    supform: 'Регистрация исполнителя',
    supfio: 'Фамилия Имя',
    supcompkey: 'Код компании (укажите, если знаете этот код)',
    suppswconf: 'Повторите пароль',
    suptext1: 'Регистрируясь, Вы принимаете',
    suptext2: 'Пользовательское соглашение',
    suptext3: 'и',
    suptext4: 'Политику конфиденциальности',
    suptext6: 'платформы Smart IT',
    suptext5: 'Необходима помощь?',
    suprefterms: 'https://smartit.digital/documents',
    suprefpolicy: 'https://smartit.digital/documents',
    pswhint: 'Не менее 8 символов, ЗАГЛАВНЫЕ и строчные буквы, и цифры',
    pswconf: 'Повторите новый пароль',
    qform: 'Пожалуйста, отправьте Ваш запрос',
    qlink: 'Отправьте запрос',
    qcompany: 'Компания',
    qphone: 'Телефон',
    qsubject: 'Тема обращения',
    qmessage: 'Сообщение',
    qattachment: 'Перетащите файл сюда',
    rpform: 'Пожалуйста, введите Ваш e-mail'
  },
  act: {
    login: 'Войти',
    reg: 'Присоединяйтесь!',
    supreg: 'Зарегистрироваться',
    chngok: 'Сменить пароль',
    qsend: 'Отправить',
    forgetpsw: 'Забыли пароль?',
    resetpsw: 'Сбросить пароль'
  },
  msg: {
    toauth: "Требуется авторизация",
    invalidpsw: "Некорректная учётная запись либо пароль",
    supcompnotfound: "Компания с указанными Вами кодом не найдена",
    supchkemailpsw: "Пароль не должен совпадать с электронной почтой",
    pswchkold: "Некорректный пароль",
    pswchanged: "Ваш пароль успешно изменён",
    pswchknewold: "Новый пароль должен отличаться от старого",
    pswchknewlogin: "Пароль не должен совпадать с учётной записью",
    pswchkdiff: "Подтверждающий пароль отличается",
    pswchku: "Пароль не соответствует требованиям, указанным выше",
    qsent: "Сообщение отправлено",
    qsending: "Отправка сообщения",
    qsenderror: "Ошибка отправки сообщения",
    rpresetok: "Новый пароль отправлен Вам на электронную почту",
    suprefinvalid: 'Некорректная ссылка'
  }
};