export var smaCustomer = {
  // ADMINISTRATOR mode, administrating users and companies
  lab: {
    cardRec: '',
    cardTab: ''
  },
  name: 'Company',
  actReviews: 'Reviews',
  url: 'Website',
  phone: 'Phone',
  email: 'E-mail',
  country: 'Country of registration',
  note: 'About',
  save: 'Save',
  employees: 'Employees',
  employee: 'Employee',
  jobPosition: 'Position',
  companyRec: 'Company',
  companyTab: 'Companies',
  requisites: 'Company details',
  urName: 'Legal name',
  regAddr: 'Registration address',
  inn: 'Tax number',
  kpp: 'Registration code',
  bankReq: 'Bank details',
  agreement: 'Contract details'
};
export var customer = {
  lab: {
    rec: 'Client',
    tab: 'Clients',
    name: 'Name',
    rate: 'Rating',
    note: 'About',
    templateNum: 'Request number template',
    counterNum: 'Request number counter'
  },
  act: {},
  msg: {}
};