export default {
  lab: {
    newRequests: 'Новые заявки',
    pending: 'Ожидание',
    rejected: 'Отклонено',
    todo: 'Старт работ',
    inProgress: 'В работе',
    done: 'Готово',
    cancelled: 'Отменено',
    accept: 'Принять',
    estimate: 'Оценить',
    reject: 'Отклонить',
    update: 'Изменить',
    rate: 'Оценить заказчика'
  },
  format: {
    formatIntervalWithDays: 'DDд HH:MM',
    formatIntervalWODays: 'HH:MM:SS'
  }
};