var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "q-btn-dropdown",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: !_vm.allDisable,
          expression: "!allDisable",
        },
      ],
      class: [
        "without-icon",
        "row-action-button",
        { "row-button-down": _vm.showing },
        { "row-button-disable": _vm.allDisable },
      ],
      attrs: {
        comment: "Кнопка с действиями внутри таблицы",
        flat: "",
        rounded: "",
        push: "",
        "menu-anchor": "bottom left",
        "menu-self": "top left",
      },
      scopedSlots: _vm._u([
        {
          key: "label",
          fn: function () {
            return [
              _c(
                "div",
                { staticClass: "row items-center no-wrap" },
                [
                  _c("q-icon", {
                    class: ["transition-rotate"],
                    attrs: { left: "", name: "more_vert" },
                  }),
                ],
                1
              ),
            ]
          },
          proxy: true,
        },
      ]),
      model: {
        value: _vm.showing,
        callback: function ($$v) {
          _vm.showing = $$v
        },
        expression: "showing",
      },
    },
    [
      _c(
        "q-list",
        { attrs: { calss: "row-action-button-items" } },
        _vm._l(_vm.visItems, function (item) {
          return _c(
            "q-item",
            {
              directives: [{ name: "close-popup", rawName: "v-close-popup" }],
              key: item.act,
              attrs: {
                clickable: "",
                disable: !_vm.aftobjFrom.aftLicence(item.act),
              },
              on: {
                click: function ($event) {
                  _vm.aftobjFrom[item.method](item.act, _vm.rowData)
                },
              },
            },
            [
              item.icon
                ? _c(
                    "q-item-section",
                    {
                      staticClass: "row-action-button-item-icon",
                      attrs: { avatar: "" },
                    },
                    [
                      _c("q-icon", {
                        attrs: { color: "primary", name: item.icon },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "q-item-section",
                [_c("q-item-label", [_vm._v(_vm._s(_vm.$t(item.label)))])],
                1
              ),
            ],
            1
          )
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }