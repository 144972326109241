export default {
  // REQUESTS & PBIS mode, creating, processing the request (a clinet view)
  lab: {
    rec: 'Antrag',
    name: 'Name',
    note: 'Kommentare',
    num: 'Nummer',
    addreqcap: 'Neuer Antrag',
    servname: 'Product / Dienstleistung',
    description: 'Beschreibung',
    docs: 'Dokumente',
    yourdocs: 'Deine Dokumente',
    customerdocs: 'Klienten Dokumente',
    start: 'Start',
    term: 'Frist',
    minTerm: 'Minimale Frist',
    termForCustomer: 'Erwartete Frist',
    val: 'Preis',
    accordion1: 'Genehmigung beantragen',
    accordion2: 'Implementierung',
    accordion3: 'Prüfung',
    accordion4: 'Architektonische Kontrolle / Bereitstellung',
    accorditionIniwin: 'Auswahl des Mitwirkenden',
    accorditionTop5: 'Top-5 Mitwirkende',
    stages: 'Schritte und Dauer',
    event: 'Schritt',
    eventdtstart: 'Start',
    eventdt: 'Fertig',
    stname: 'Status',
    state: 'Status',
    customer_name: 'Klient',
    executor_name: 'Mitwirkender',
    initiator_name: 'Antragsteller',
    responsible: 'Verantwortlich',
    expected: 'Erw. Frist',
    expectedfull: 'Erwartete Frist',
    period: 'Zeitraum',
    dt: 'Erstellungsdatum',
    tillTrade: 'Bis zum Ende der Gebotsabgabe:',
    tillInnerExec: 'Bis zum Ende der Zuteilung eines internen Mitarbeiters:',
    tillChoose: 'Bis zum Ende der Auswahl des erfolgreichen Bieters:',
    promptDecline: 'Grund für die Ablehnung',
    promptComment: 'Kommentare (optional)',
    promptAlter: 'Grund für die Auswahl eines alternativen Gewinners',
    saveMsg: 'Speichern...',
    mainOffer: 'Hauptangebot',
    laborCosts: 'Aufwand',
    hour: 'Std.',
    altOffer: 'Alternativangebot',
    proposedTerm: 'Vorgeschlagene Frist',
    propTerm: 'Vorgeschlagene Frist',
    discount: 'Nachlass',
    noteExec: 'Kommentare der Mitwirkenden',
    actExecAcceptSuccess: 'Der Antrag wird angenommen',
    exec: 'Ausführung',
    usePower: 'Nutzen Sie die Gelegenheit, Ihr Angebot durch erneutes Bieten zu verbessern',
    costDeviationGT: 'Ihre vorgeschlagenen Aufwendungen liegen {devCost}% über dem Durchschnitt',
    costDeviationLT: 'Beim Bieten gehörten Ihre Aufwendungen zu den besten! Bitte beachten Sie, dass beim wiederholten Bieten andere Mitwirkende ihre Anträge verbessern können.',
    costDeviationEQ: 'Ihre vorgeschlagenen Aufendungen entsprechen dem Durchschnitt',
    termDeviationGT: 'Ihre vorgeschlagenen Fristen sind {devTerm}% länger als der Durchschnitt',
    termDeviationLT: 'Ihre vorgeschlagenen Fristen sind {devTerm}% kürzer als der Durchschnitt',
    termDeviationNC: 'Laut Auktionsergebnis gehörten Ihre Arbeitskosten zu den Besten! Bitte beachte, dass',
    termDeviationIA: 'bei der Nachbietung können andere Künstler ihre Angebote verbessern.',
    termDeviationEQ: 'Ihre vorgeschlagenen Fristen entsprechen dem Durchschnitt',
    medalFirst1: 'Herzlichen Glückwunsch! Ihr ',
    medalFirst2: 'alternatives ',
    medalFirst3: 'Angebot wurde akzeptiert.',
    medalFirst4: 'Sie wurden dem Antrag als Mitwirkender zugewiesen.',
    medalSecond: 'Bitte bestätigen Sie den Beginn der Arbeit an dem Antrag.',
    winnerSecond1: 'Wir empfehlen Ihnen ',
    winnerSecond2: 'das Hauptangebot ',
    winnerSecond3: 'das Alternativangebot ',
    winnerSecond4: 'auszuwählen.',
    winnerThird: '{winName} erledigt die Arbeit in {winProc}% der Fälle fristgerecht.',
    hcolSt1: 'Status vorher',
    hcolSt2: 'Status danach',
    hcolReason: 'Grund der Ablehnung',
    hcolComment: 'Kommentare',
    chooseExecutor: 'Wählen Sie den Mitwirkenden',
    tradeWinner: 'Der erfolgreiche Bieter',
    otherExecs: 'Andere Bieter',
    initChosen: 'Der vom Antragsteller ausgewählte Mitwirkende',
    execRecom: 'Wir empfehlen den Mitwirkenden',
    testingCussessful: 'Prüfung erfolgreich',
    testingFailed: 'Prüfung gescheitert',
    deployCussessful: 'Architektische Kontrolle / Bereitstellung erfolgreich',
    deployFailed: 'Architektische Kontrolle / Bereitstellung gescheitert',
    execChosen: 'Der Mitwirkende wird ausgewählt',
    valDeltaGT: 'Das Angebot ist {valDelta}% teurer als ',
    valDeltaLT: 'Das Angebot ist {valDelta}% günstiger als ',
    valDeltaEQ: 'Das Angebot entspricht ',
    platformRecom0: 'was von der Plattform empfohlen wurde',
    platformRecom: 'was von der Plattform empfohlen wurde',
    daysDelta0: 'Die Frist entspricht der ursprünglichen Frist',
    daysDelta: 'Die Frist ist {daysDelta} Werktage später als die ursprüngliche',
    smartChoose: 'Auswahl der Plattform',
    innerChoose: 'Einen internen Mitwirkenden zuweisen',
    reqInWork: 'Laufende Anträge',
    free: 'Frei',
    busy: 'Beschäftigt',
    overloaded: 'Überlastet',
    platformName: 'Smart IT Plattform',
    tenders: '0 eingereichte Angebote | 1 eingereichtes Angebot | {n} eingereichte Angebote',
    cntRebids: '0 Nachbietungen. | 1 Nachbietung. | {n} Nachbietungen.',
    platformChooseText: 'Der Gewinner wird auf der Grundlage der Kriterien Preis ({valScale}%), Qualität ({qualScale}%), Mindestbewertung ({minrate}) ausgewählt unter ',
    platformChooseText1: ' entsprechend den Ergebnissen der Ausschreibung und ',
    coordChoose: 'Die Auswahl des Mitwirkenden wurde genehmigt',
    initChooseLongDesc1: '',
    initChooseLongRating: '(Bewertung {valRate})',
    initChooseLongDesc2: ' wurde als Mitwirkender ausgewählt mit ',
    initChooseLongDesc3Main: ' dem Haupt',
    initChooseLongDesc3Alter: ' dem Alternativ',
    initChooseLongDesc4: 'angebot mit dem Preis ',
    initChooseLongDesc5: ' und ',
    initChooseLongDesc6Main: ' der angeforderten ',
    initChooseLongDesc6Alter: ' der vorgeschlagenen ',
    initChooseLongDesc7: ' Frist ',
    platformChooseLongDesc1: 'Dieses Angebot unterscheidet sich von dem von der Smart IT Plattform empfohlenes Angebot: der Mitwirkende ',
    platformChooseLongDesc2: ' mit ',
    chosenOffer: 'Ausgewähltem Angebot',
    initOfferChosen: 'Die Auswahl des Antragstellers',
    innerChooseLongDesc1: '',
    innerChooseLongDesc2: ' wurde als Mitwirkender zugewiesen',
    offerNewValue: 'Einen neuen Preis vorschlagen:',
    withoutChangeCost: 'Ohne den Preis des Antrags zu ändern: ',
    innerComment: 'Interne Kommentare',
    comment4Exec: 'Kommentare für den Mitwirkenden',
    changeReq: 'Ändern des Antrags',
    changeReqReq: 'Antrag ändern',
    changeReqReqConf: 'Änderungsantrag bestätigt',
    changeReqReqRej: 'Änderungsantrag abgelehnt',
    newReqValue: 'Vorgeschlagener Zahlungsbetrag: ',
    newReqValueProc: ', was {proc}% des Antragspreises entspricht.',
    comment4Init: 'Kommentare für den Klienten',
    cancelReq: 'Stornierung des Antrags',
    offerAmount: 'Schlagen Sie den Zahlungsbetrag für den Antrag vor:',
    fullRequestCost: 'Der volle Preis des Antrags: ',
    newCostValue: 'Definieren Sie den Zahlungsbetrag: ',
    recommendVal: '{recProc}% des Antragspreises: ',
    recommend: '(empfohlen)',
    spendedVal: 'Im Verhältnis zum Zeitaufwand ({spendProc}%): ',
    changeReqCan: 'Stornierungsantrag',
    cancelReqInfo1: ' Dies ist ',
    cancelReqInfo2: 'die Standardbedingung ',
    cancelReqInfo3: 'für die Stornierung in dieser Phase.',
    cancelReqReqConf: 'Stornierungsantrag bestätigt',
    cancelReqReqRej: 'Stornierungsantrag  abgelehnt',
    leaveComment: 'Kommentare',
    reqAgree: 'Antrag genehmigt',
    reqAgreeToCoord: 'Antrag zur Genehmigung an den Produktbesitzer / Koordinator geschickt',
    reqCancel: 'Antrag abgelehnt',
    indPeriod: 'Definieren Sie die Frist, zu der Sie den Antrag billiger realisieren könnten',
    actualizing: 'Status-Aktualisierung',
    sendToTest: 'Zur Prüfung eingereicht',
    sendToDeploy: 'Eingereicht zur Architektonische Kontrolle / Bereitstellung',
    sendToComplete: 'Abgeschlossen',
    makingRequest: 'Antrag erstellen',
    editingRequest: 'Antrag aktualisieren ',
    smartWin: 'Auswahl eines Mitwirkenden im automatischen Modus',
    rating: 'Bewertung',
    initRequest: 'Antrag erstellen',
    startWorkConfirm: 'Bestätigung des Arbeitsbeginns',
    offerDeclined: 'Der Arbeitsbeginn ist nicht bestätigt.',
    offerDeclinedTxt: 'Der Mitwirkende hat den rechtzeitigen Arbeitsbeginn nicht bestätigt',
    notActual: 'Nicht gültig',
    tradeInfo1: 'Der Antrag befindet sich im {stcode}{numreb} Stadium. Die verbleibende Zeit bis zum Ende von {stcode}: ',
    tradeInfo1Bid: 'Bieten',
    tradeInfo1Rebid: 'Nachbieten',
    tradeInfo2: '{cur} {alltender} aus {allexecs} reichten ihre Angebote ein, darunter {allinner} und {allouter}. Angebote mit einer Frist, die der beantragten entspricht: {allactual}.',
    tradeInfo2CurMoment: 'Momentan,',
    tradeInfo2CurResult: 'Basierend auf den Ergebnissen der Gebote,',
    tradeInfo2CurResult2: 'Basierend auf den Ergebnissen von Geboten und Nachbietungen,',
    tradeInfo2AllExecs: '0 zugelassene Mitwirkende | {n} zugelassener Mitwirkender | {n} zugelassene Mitwirkende | {n} zugelassene Mitwirkende',
    tradeInfo2AllInner: '0 interne | {n} interne | {n} interne  | {n} interne ',
    tradeInfo2AllOuter: '0 ausgelagerte Mitwirkende | {n} ausgelagerter Mitwirkender | {n} ausgelagerte Mitwirkende | {n} ausgelagerte Mitwirkende',
    tradeInfo3: 'Der Antrag {allrej} beinhaltend {allinnerrej} und {allouterrej}.',
    tradeInfo3Execs: 'wurde von 0 Mitwirkenden abgelehnt | wurde von {n} Mitwirkenden abgelehnt | wurde von {n} Mitwirkenden abgelehnt | wurde von {n} Mitwirkenden abgelehnt',
    tradeInfo4Req: 'Am Ende der Gebotsabgabe wird mit der Klärung der Anforderungen begonnen, woraufhin die erneute Gebotsabgabe folgt.',
    tradeInfo4BidRebid: 'Am Ende der Gebotsabgabe beginnt die erneute Gebotsabgabe (die Anzahl der Durchläufe: {rebidcnt}), danach folgt die Auswahl des Mitwirkenden.',
    tradeInfo4Exec: 'Am Ende der Gebotsabgabe folgt die Auswahl des Mitwirkenden.',
    tradeInfo4Rebid: 'Am Ende der Nachbietung (die Anzahl der Durchläufe: {rebidcnt}) folgt die Auswahl des Mitwirkenden.',
    tradeInfo5Res: 'Im Zuge der Nachbietung, ',
    tradeInfo5Execs: '0 Mitwirkende verbessert | {n} Mitwirkender verbessert | {n} Mitwirkende verbessert | {n} Mitwirkende verbessert ',
    tradeInfo5ExecsDown: '0 Mitwirkende verschlechtert | {n} Mitwirkender verschlechtert | {n} Mitwirkende verschlechtert | {n} Mitwirkende verschlechtert ',
    tradeInfo5Con: ' das Angebot im Vergleich zur Gebotsabgabe. Im Durchschnitt betrug der Preis des Hauptangebots {avgrebid}%, der maximale Rückgang {maxrebid}%.',
    tradeInfo5ConLT: 'sank um ',
    tradeInfo5ConGT: 'stieg um ',
    endOfBid: 'Gebotsabgabe abgeschlossen',
    endOfRebid: 'Gebotsabgabe und Nachbietung abgeschlossen',
    tradeInfoReq2: 'Der Antrag befindet sich in der Phase der Abklärung der Anforderungen.',
    tradeInfoReqWLink31: 'Wir bitten Sie, Sitzungen abzuhalten ',
    tradeInfoReqWLink32: 'mit Bietern',
    tradeInfoReqWLink33: ' ihre aktuellen Angebote vorzustellen und Verhandlungen zu führen.',
    tradeInfoReq5: 'Um gleiche Wettbewerbsbedingungen zu gewährleisten und den Wettbewerb zu verbessern, können Sie eine gemeinsame Sitzung mit allen Bietern abhalten, um die Anforderungen zu klären. Auf diese Weise erhalten sie gleichen Zugang zu Informationen, einschließlich Antworten auf Fragen anderer Mitwirkender.',
    isClarificationNeed: 'Müssen Sie die Voraussetzungen für den Auftrag klären?',
    stateRequrements: 'Klarstellung der Anforderungen',
    tradeInfoReqRebid: 'Am Ende dieser Phase beginnt die Neuausschreibung (die Anzahl der Durchläufe: {rebidcnt}), danach folgt die Auswahl des Mitwirkenden.',
    and: ' und ',
    wagerate: 'Stundensatz ohne MWST',
    laborCostsW: 'Erw. Aufwendungen, h',
    laborCostsF: 'Tats. Aufwendungen, h',
    payfact: 'Genehmigung der Arbeitskosten',
    factval: 'Tats. Kosten',
    factvalInfoExec1: 'Tatsächliche Aufwendungen waren ',
    factvalInfoExec2: '0 Stunden | {n} Stunde | {n} Stunden | {n} Stunden ',
    factvalInfoExec3Eq: ', was den erwarteten Anstrengungen entspricht. ',
    factvalInfoExec3Gt: ', was {proc}% höher ist als die geplanten Aufwendungen. ',
    factvalInfoExec3Lt: ', was {proc}% niedriger ist als die geplanten Aufwendungen. ',
    factvalInfoExec4: 'Die tatsächlichen Arbeitskosten waren {factval} {cur} ohne Mehrwertsteuer mit dem Stundensatz des Mitwirkenden {wagerate} {cur}.',
    factvalAppWait: 'Ausstehende Genehmigung durch den folgenden Verantwortlichen: ',
    factvalBefore: 'Der Antrag ist abgeschlossen. Der Mitwirkende bereitet einen Bericht über die tatsächlichen Arbeitskosten vor.',
    payfactCorrect: 'Anpassung der Arbeitskosten',
    tradeOffer: 'Einreichen eines Angebots',
    platformCommentReturnFromTrade: 'Der Antrag wurde aufgrund fehlenden Angeboten von Mitwirkenden aus der Phase der Auswahl des Mitwirkenden zurückgesandt. Sie können die Gebotsabgabe fortsetzen, nachdem Sie die Anforderungen und Zeitrahmen des Antrags angepasst haben.',
    returnFromTrade: 'Rücksendung aus der Auswahl des Mitwirkenden',
    reprequestlist: 'Antragsbericht',
    repTender: 'Bericht des ausgewählten Mitwirkenden',
    curLaborCosts: 'Aktueller Arbeitsaufwand',
    share: 'Teilen',
    copyText: 'Link zu diesem Antrag:'
  },
  event: {
    start: 'Start',
    coordagree: 'Genehmigung durch einen Produktbesitzer / Koordinator',
    trade: 'Gebotsabgabe',
    requirements: 'Klarstellung der Anforderungen',
    rebid: 'Nachbieten',
    initwinner: 'Auswahl des Mitwirkenden',
    coordwinner: 'Freigabe des ausgewählten Mitwirkenden',
    startconf: 'Bestätigung des Arbeitsbeginns',
    stanterm: 'Implementierung',
    accepttest: 'Prüfung',
    deploy: 'Architektonische Kontrolle / Bereitstellung',
    payfact: 'Genehmigung der tatsächlichen Arbeitskosten',
    innerexec: 'Zuweisung einer internen Mitwirkenden'
  },
  act: {
    add: 'Neuen PBI / Antrag erstellen',
    decline: 'Ablehnen',
    copy: 'Duplizieren',
    additional: 'Mehr',
    conform: 'Genehmigen',
    accept: 'Akzeptieren',
    confirm: 'Bestätigen',
    beginWork: 'Fortfahren',
    actualize: 'Status-Aktualisierung',
    acceptanceTest: 'Prüfung',
    deployment: 'Architektonische Kontrolle / Bereitstellung',
    complete: 'Abgeschlossen',
    refuse: 'Ablehnen',
    test: 'Weiter zur Prüfung',
    stop: 'Beenden',
    defectsElim: 'Mängel beseitigen',
    chooseInner: 'Einen internen Mitwirkenden zuweisen',
    choose: 'Auswählrn',
    chooseExec: 'Den Gewinner auswählen',
    estimate: 'Rate',
    stopTrade: 'Gebotsabgabe stoppen',
    stopRebid: 'Nachbieten stoppen',
    acceptOffer: 'Angebot annehmen',
    viewOffer: 'Angebot ansehen',
    showAllOffers: 'ALLE Angebote ansehen',
    expand: 'Anträge ausklappen',
    collapse: 'Anträge einklappen',
    assign: 'Zuweisen',
    editRequest: 'Antrag bearbeiten',
    toWork: 'Fortfahren',
    correct: 'Änderungantrag bearbeiten',
    cancel: 'Antrag abbrechen',
    startRebid: 'Zum Nachbieten fortfahren',
    proceed: 'Fortfahren',
    toExcel: 'Nach Excel exportieren',
    paidCustomer: 'Vom Klienten bezahlt',
    settlements: 'Gegenseitige Zahlungen abgeschlossen',
    makeReport: 'Bericht generieren',
    repRequest: 'Antragsbericht'
  },
  activity: {
    needAct: 'Handlungsbedarf',
    wait: 'Ausstehend',
    work: 'In Bearbeitung',
    history: 'Verlauf'
  },
  period: {
    week: 'Woche',
    month: 'Monat',
    year: 'Jahr'
  },
  msg: {
    tableRebiddingParticipants: 'Teilnehmer der vorherigen Ausschreibungsrunde',
    tableRebiddingLaborCostsH: 'Arbeitskosten, h',
    tableRebiddingBidPrice: 'Geldkurs der vorherigen Handelsrunde, Rubel',
    tableRebiddingMemberNo: 'Mitglied № ',
    tableRebiddingYourSuggestion: '(Ihre Vorschläge)',
    confirmDecline: 'Den Antrag ablehnen?',
    confirmAccept: 'Den Antrag annehmen?',
    confirmEstimate: 'Den Antrag schätzen?',
    needVal: 'Der Preis sollte festgelegt werden',
    needLaborCost: 'Die Arbeitskosten sollten festgelegt werden',
    needDiscount: 'Der Nachlass sollte definiert werden',
    needAltTerm: 'Wenn der Rabatt genannt wird, sollte eine alternative Frist definiert werden',
    laborRebid: 'Die Aufwendungen in dem verbesserten Angebot können die Aufwendungen in dem ursprünglichen Angebot nicht übertreffen.',
    dateRebid: 'Die vorgeschlagene Frist in dem verbesserten Angebot kann nicht später sein als die Frist in dem ursprünglichen Angebot.',
    minRate: 'Keine akkreditierten Mitwirkenden, die die Mindestanforderung an die Bewertung erfüllen',
    altTerm: 'Der vorgeschlagene Termin sollte zeitlich später liegen als der beantragte',
    altervalRebid: 'Der Preis des verbesserten Alternativangebots darf nicht höher sein als der Preis des ursprünglichen Alternativangebots',
    valService: 'Der Preis des verbesserten Angebots darf nicht höher sein als der Preis des ursprünglichen Angebots.',
    needTerm: 'Die Frist sollte festgelegt werden',
    needExpected: 'Die erwartete Frist sollte festgelegt werden',
    needExpectedAfterNow: 'Die erwartete Frist kann nicht vor dem aktuellen Datum liegen',
    needDescription: 'Die Beschreibung ist erforderlich',
    needComment: 'Die Kommentare sind erforderlich',
    tradeStopped: 'Gebotsabgabe gestoppt',
    rebidStopped: 'Nachbieten gestoppt',
    wrongTerm: 'Bitte überprüfen Sie, ob die Frist korrekt ist.',
    alterComment: 'Bitte fügen Sie die Kommentare zur Auswahl des alternativen Mitwirkenden hinzu',
    alterInitComment: 'Bitte fügen Sie die Kommentare zur Auswahl des alternativen Angebots hinzu',
    actEditRequestConfirm: 'Sind Sie sicher, dass Sie den Antrag während seiner Umsetzung ändern wollen?',
    needOfferVal: 'Bitte legen Sie den neuen Preis des Antrags fest',
    actEditRequestExecDecline: 'Sind Sie sicher, dass Sie den Änderungsantrag ablehnen möchten? In diesem Fall wird die Umsetzung fortgesetzt, aber der Antrag kann von beiden Seiten vollständig abgebrochen werden.',
    actCancelRequestConfirm: 'Sind Sie sicher, dass Sie den Antrag während seiner Durchführung vollständig stornieren wollen?',
    needOfferVal4Cancel: 'Bitte definieren Sie den Zahlungsbetrag',
    actCancelRequestExecDecline: 'Sind Sie sicher, dass Sie den Stornierungsantrag ablehnen möchten? In diesem Fall wird die Zahlung für die gemäß den vorgeschlagenen Bedingungen geleistete Arbeit nicht durchgeführt',
    needComment4Exec: 'Bitte fügen Sie die Kommentare für den Mitwirkenden hinzu',
    platformCommentActualizeStatus: 'In Bearbeitung: {0}%, der aktuelle Arbeitsaufwand {2} St., der erwartete Termin: {1:dt}.',
    // The text in brackets should not be translated!
    needFactEfforts: 'Bitte geben Sie die tatsächlichen Arbeitskosten für den Antrag auf Genehmigung durch den Klienten an',
    wrongDateFormat: 'Bitte überprüfen Sie, ob Datum und Uhrzeit korrekt sind.',
    needHourlyRate: 'Bitte geben Sie den Stundensatz an',
    cancelAccepted: 'Stornierung bestätigt',
    cancelDeclined: 'Stornierung abgelehnt',
    toExcel: 'Nur die Anträge werden in die Microsoft Excel-Datei hochgeladen, die derzeit im Abschnitt "PBIs & Anträge" gezeigt sind. Bitte stellen Sie sicher, dass alle unnötigen Filter entfernt werden.'
  }
};