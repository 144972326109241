export default {
  // ADMINISTRATOR mode, administrating users, companies and other dictionaries
  menu: {
    account: 'Users',
    role: 'Roles',
    logout: 'Sign out',
    newpsw: 'Change password',
    company: 'Companies',
    cust: 'Clients',
    administrator: 'Administrator',
    request: 'PBIs & Requests',
    dblocale: 'User interface translations',
    querysubject: 'Message subjects'
  },
  lab: {
    messerr: 'Error',
    messinfo: 'Information',
    messconf: 'Confirm action',
    addrec: ' (add a record)',
    updrec: ' (edit the record)',
    viewrec: ' (view the record)',
    captcardAdd: 'New record',
    captcardUpdate: 'Edit',
    captcardView: 'View',
    captcardTab: 'Relevant records',
    captcardTabAddle: 'Deleted records',
    captcardList: 'Select',
    captcardRse: 'Database filter',
    captcardRseAddle: 'Database filter (deleted)',
    captcardHistory: 'Change history of the ID record: {id}',
    // The text in brackets should not be translated!
    hcolId: 'Stamp ID',
    hcolActual: 'Relevance',
    hcolOper: 'Action',
    hcolStamp: 'When',
    hcolUser: 'Who',
    hoper: {
      I: 'Created',
      U: 'Changed',
      D: 'Deleted'
    },
    from: 'from',
    to: 'to',
    Yes: 'Yes',
    No: 'No'
  },
  act: {
    actOk: 'Execute',
    actCan: 'Cancel',
    actReturn: 'Return',
    actSelect: 'Execute',
    actAdd: 'Add a new record',
    actUpdate: 'Update',
    actView: 'Detailed',
    actDelete: 'Delete',
    actFind: 'Table search',
    actDeleteP: 'Delete selected records permanently',
    actClearRec: 'Clear the card',
    actFreshAddleF: 'Show actual records',
    actFreshAddleA: 'Show deleted records',
    actRepairAddle: 'Restore selected records',
    actHistory: 'Change history',
    actExcel: 'Export to Excel',
    actFilter: 'Open the filter card',
    actDeleteM: 'Delete selected records',
    actSwitchMarksOn: 'Swith on the selection',
    actSwitchMarksOff: 'Swith off the selection',
    actExtActs: 'Additional functions',
    save: 'Save',
    cancel: 'Cancel',
    select: 'Execute',
    add: 'Add a new record',
    view: 'View',
    update: 'Edit selected records',
    delete: 'Delete selected records',
    choice: 'Select',
    updateOne: 'Update',
    deleteOne: 'Delete',
    excel: 'Export to Excel',
    deleteP: 'Delete selected records permanently',
    repairAddle: 'Restore selected records',
    menuFresh: 'To actual records',
    menuAddle: 'To deleted records',
    history: 'Change history',
    historyhint: 'Show change history',
    find: 'Search',
    yes: 'Yes',
    no: 'No',
    auth: 'Authentication',
    addFiles: 'Drag and drop a file here'
  },
  msg: {
    reqauth: 'Authentication is required',
    recSavedSuccess: 'Saved succesfully',
    confirmDelete: 'Delete selected records?',
    chkRec: 'The form is filled out incorrectly',
    notFound: 'Not found',
    btnClose: 'Close',
    btnCancel: 'Cancel',
    btnOk: 'OK',
    btnSave: 'Save',
    btnApprove: 'Proceed',
    fillAllMandatoryFields: 'Please complete all required fields',
    fillMandatoryField: 'This field is required',
    fillMandatoryStringField: 'This field is required',
    fillMandatoryIntField: 'This field can only contain numbers',
    fillMandatoryIntFieldBetween: 'Please enter a number between {minValue} and {maxValue}',
    // The text in brackets should not be translated!
    fillNumFieldBetween: 'Please enter a number between {minValue} and {maxValue}',
    // The text in brackets should not be translated!
    fillNumFieldGT: 'Please enter a number greater than {minValue}',
    // The text in brackets should not be translated!
    uploadFile: 'The files are being uploaded...',
    uploadFileError: 'The files failed to upload',
    uploadFileSize: 'The file {fileName} is larger than {fileSize} MB',
    // The text in brackets should not be translated!
    systemError: 'System error. Please try again later',
    pageLoading: 'The page is loading...',
    pageAccessViolation: 'You do not have access to this page'
  },
  dateFormat: 'mm/dd/yyyy',
  // No translation needed!
  dateMask: '##/##/####',
  // No translation needed!
  dateTimeFormat: 'mm/dd/yyyy hh:mi:ss',
  // No translation needed!
  dateTimeWoSecFormat: 'MM/DD/YYYY HH:mm',
  // No translation needed!
  dateTimeWoSecFormatSpaced: 'MM/DD/YYYY             HH:mm',
  // No translation needed!
  dateTimeWoSecMaskSpaced: '##/##/####             ##:##',
  // No translation needed!
  curLocale: 'en' // No translation needed!
};