export var aftAvatar = {
  tooBigAvatar: 'Изображение слишком большого размера. Пожалуйста, выберите другой файл',
  avatarInvalidExtension: 'Пожалуйста, используйте файлы с расширением jpg, jpeg или png'
};
export var aftLogo = {
  tooBigLogo: 'Изображение слишком большого размера. Пожалуйста, выберите другой файл',
  logoInvalidExtension: 'Пожалуйста, используйте файлы с расширением jpg, jpeg или png'
};
export var smaCurrency = {
  lab: {
    rec: '',
    tab: 'Валюта'
  },
  label: {
    tabCaption: 'Валюта',
    name: 'Наименование',
    code: 'Код',
    symbol: 'Символ'
  }
};
export var comLang = {
  lab: {
    rec: '',
    tab: 'Язык'
  },
  label: {
    tabCaption: 'Язык',
    name: 'Наименование',
    code: 'Код',
    active: 'Интерфейсный',
    localeCode: 'Региональный код',
    defShortDateFmt: 'Формат даты',
    defLongDateFmt: 'Формат даты и времени'
  }
};
export var dashboard = {
  label: {
    tabCaption: 'Пользователи аналитики'
  },
  lab: {
    name: 'Пользователь аналитики',
    link: 'Ссылка',
    dashboardSave: 'Сохранить'
  }
};
export var analytics = {
  lab: {
    tab: 'Аналитика'
  }
};