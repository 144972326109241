export default {
  // PRODUCTS & SERVICES mode, configuring a product / service
  lab: {
    rec: 'Services',
    tab: 'Services',
    tabPlural: 'Products & Services',
    service: 'Product / Service',
    tabMain: 'General Settings',
    tabStages: 'Steps and Duration',
    tabAccs: 'Actors',
    tabExecs: 'Contributors',
    tabIn: 'In-house Contributors',
    tabOut: 'Outsourced Contributors',
    name: 'Name',
    custname: 'Client',
    note: 'Description',
    iso: 'Currency',
    budget: 'Budget',
    gen: 'Standard',
    lang: 'Languages',
    skills: 'Skills',
    minrate: 'Minimum rating',
    genclaim: 'Standard request (standard price)',
    stansellperiod: 'Implementation (for standard requests)',
    maxclaimval: 'Maximum (initial) price',
    maxclaimvalinc: 'Rebidding price increase',
    rebidcnt: 'Iterations of rebidding',
    valqualscale: 'Criteria for selecting a winning bidder',
    scaleprice: 'Price',
    scalequality: 'Quality',
    calendar: 'Calendar',
    coordagreeperiod: 'Approval by a product owner / сoordinator',
    tradeperiod: 'Bidding',
    rebidperiod: 'Rebidding',
    show_bid_prices: 'Announcement of bid prices',
    manualChoose: 'Manual selection of a сontributor',
    startconfperiod: 'Confirming the start of work',
    accepttestperiod: 'Testing',
    deployperiod: 'Architectural assurance / deployment',
    innerperiod: 'Assigning an in-house сontributor',
    collaballow: 'Attraction of co-contributors is allowed',
    acccust: 'Requesters (creating PBIs & requests, selecting contributors, testing)',
    servAcc: 'Responsible',
    acccoord: 'Product Owners / Coordinators (approving PBIs & requests and selected contributors)',
    accitexp: 'Architects / IT Specialists (architectural assurance, deployment)',
    accitcoord: 'IT Coordinators (managing the product / service, assigning in-house contributors)',
    url: 'URL',
    rsereqcap: 'Find services',
    execname: 'Contributor',
    framework: 'Agreement Concluded',
    frameworkDlg: 'Contract / framework agreement concluded',
    wagerate: 'Hourly rate w/o VAT',
    startRating: 'Initial rating',
    addRequest: 'Create Product / Request',
    stname: 'Status',
    coordrequired: "Product owner / coordinator's approval required",
    coordCond: 'Only if the price is exceeded by',
    files: 'Documents and Links',
    pickFile: 'Drag and drop a file here',
    jobPosition: 'Position',
    requirementsperiod: 'Clarification of the requirements (non-standard requests)',
    notgenclaimFix: 'Non-standard request (fixed price)',
    notgenclaimFact: 'Non-standard request (time & material)',
    claimType: 'Request type',
    payfactLabel: 'Responsible for approving the actual labor costs of the contributor',
    cust: 'Requester',
    coord: 'Product Owner / Coordinator',
    itcoord: 'IT Coordinator',
    itspec: 'Architect / IT Specialist',
    payfactperiod: 'Approving the actual labor costs',
    servFreelanceLabel: 'Submit requests on freelance platforms',
    isOuterAvailable: 'Visible for external contributors',
    sendHead: 'Link for accreditation',
    sendText: 'Copy this link and send it to a new contributor so he could sign up and get accreditation for this service:'
  },
  act: {
    additional: 'More',
    additionalFilterTooltip: 'Specify additional filter criteria',
    decline: 'Not interested',
    accr: 'Accreditation',
    add: 'Create New Product / Service',
    addAcc: 'Add Responsible',
    addExec: 'Add Contributor',
    addLink: 'Add Link',
    send: 'Share',
    copy: 'Copy to Clipboard'
  },
  msg: {
    execExist: 'There is already the contributor {execName}',
    curMiss: 'The currency is not specified',
    coordAgreePeriodMiss: 'The duration of the approval by a product owner / coordinator is not specified',
    tradeperiodMiss: 'The duration of bidding is not specified',
    rebidperiodMiss: 'The duration of rebidding is not specified',
    initwinnerperiodperiodMiss: 'The duration of the manual selection of a contributor is not specified',
    coordwinnerperiodperiodMiss: "The duration of a PO / coordinator's approval of a selected contributor is not specified",
    coordwinnerperiodcondMiss: 'The price exceeding is not specified',
    startconfperiodMiss: 'The duration of confirming the start of work is not specified',
    innerperiodMiss: 'The duration of assigning an in-house contributor is not specified',
    accepttestperiodMiss: 'The duration of testing is not specified',
    deployperiodMiss: 'The duration of the architectural assurance / deployment is not specified',
    maxclaimvalMiss: 'The maximum (initial) price is not specified',
    maxclaimvalincMiss: 'The rebidding price increase is not specified',
    rebidcntMiss: 'The number of iterations of rebidding is not specified',
    stantermMiss: 'The duration of the implementation is not specified',
    downloadFile: 'The file is being downloaded...',
    uploadFile: 'The files are being uploaded...',
    tooBigFile: 'The file size is too large',
    tooBigFileSize: 'The file {fileName} is larger than 16 MB',
    wagerateMiss: 'The hourly rate is not specified',
    requirementsMiss: 'The duration of the clarification of requirements is not specified',
    rebidReqsMiss: 'The сlarification of requirements is only possible if rebidding is conducted',
    payfactMiss: 'The responsible for approving the labor costs of the contractor are not specified',
    payfactperiodMiss: 'The duration of approving the actual labor costs is not specified',
    linkCopied: 'Link copied',
    noSuchService: 'The service for which accreditation was requested does not exist',
    alreadyAccred: 'You are already registered for the "{servName}" service'
  },
  smaServiceAccrReq: {
    wageRate: 'Hourly rate w/o VAT',
    okButton: 'Apply for Accreditation'
  }
};