import "core-js/modules/es6.object.keys.js";
import "core-js/modules/es6.regexp.split.js";
import "core-js/modules/es6.array.find.js";
import "core-js/modules/es6.string.starts-with.js";
import Vue from 'vue';
import VueI18n from 'vue-i18n';
import messages from 'src/i18n';
import { getCookie, setCookie } from 'boot/aft';
import { LANGS, locale2code } from 'components/LangSelector';
import Quasar from 'quasar/src/vue-plugin.js';;

// import enUS from 'quasar/lang/en-us'
// import ru from 'quasar/lang/ru'
// import store from 'src/store'
// import { enUS as dateENUS, ru as dateRU } from 'date-fns/locale'

Vue.use(VueI18n);
function findLanguageKey(lang, source) {
  var keys = Object.keys(source);
  var short = lang.split('-')[0];
  var prefix = short + '-';
  return keys.find(function (it) {
    return it === lang;
  }) || keys.find(function (it) {
    return it === short;
  }) || keys.find(function (it) {
    return it.startsWith(prefix);
  });
}
var lang = getCookie('LANG');
if (lang === 'undefined' || typeof lang === 'undefined') {
  var browserLanguage = Quasar.lang.getLocale();
  var i18Key = findLanguageKey(browserLanguage, messages);
  lang = i18Key || 'en-US';
  // Запросы к БД возможны до авторизации, а там текущий язык устанавливается по куке
  var date = new Date();
  date.setDate(date.getDate() + 100);
  setCookie('LANG', locale2code(lang), {
    expires: date.toUTCString
  });
} else {
  lang = LANGS[lang].locale;
}
var i18n = new VueI18n({
  locale: lang,
  fallbackLocale: 'ru',
  messages: messages,
  // Key - language to use the rule for, `'ru'`, in this case
  // Value - function to choose right plural form
  pluralizationRules: {
    /**
     * @param choice {number} a choice index given by the input to $tc: `$tc('path.to.rule', choiceIndex)`
     * @param choicesLength {number} an overall amount of available choices
     * @returns a final choice index to select plural word by
     */
    ru: function ru(choice, choicesLength) {
      // this === VueI18n instance, so the locale property also exists here

      if (choice === 0) {
        return 0;
      }
      var teen = choice > 10 && choice < 20;
      var endsWithOne = choice % 10 === 1;
      if (choicesLength < 4) {
        return !teen && endsWithOne ? 1 : 2;
      }
      if (!teen && endsWithOne) {
        return 1;
      }
      if (!teen && choice % 10 >= 2 && choice % 10 <= 4) {
        return 2;
      }
      return choicesLength < 4 ? 2 : 3;
    }
  }
});

// const languages = { 'en-US': enUS, ru }
// dateENUS.code = 'en-US'
// dateRU.code = 'ru'
// const dateFormats = { 'en-US': dateENUS, ru: dateRU }

// const quasarKey = findLanguageKey(browserLanguage, languages)
// export default ({ app }) => {
//   app.i18n = i18n
//   Quasar.lang.set(languages[quasarKey || 'en-US'])
// }

// export { i18n, languages, dateFormats }
// export function setLanguage (lang) {
//   let key = findLanguageKey(lang, languages)
//   if (key) Quasar.lang.set(languages[key])
//   key = findLanguageKey(lang, i18n.messages)
//   if (key && i18n.locale !== key) {
//     i18n.locale = lang
//     store.commit('clearLocalizedData')
//   }
// }

export default (function (_ref) {
  var app = _ref.app;
  app.i18n = i18n;
  import('quasar/lang/' + lang).then(function (lang) {
    Quasar.lang.set(lang.default);
  });
});
export { i18n };