var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "q-btn",
    {
      ref: "LangButton",
      attrs: {
        icon: _vm.getIcon,
        label: _vm.getLabel,
        size: _vm.btnSize,
        dense: "",
        flat: "",
        "no-caps": "",
        "text-color": "black",
      },
    },
    [
      _c(
        "q-menu",
        [
          _c(
            "q-list",
            { staticStyle: { "min-width": "100px" } },
            [
              _c(
                "q-item",
                {
                  directives: [
                    { name: "close-popup", rawName: "v-close-popup" },
                  ],
                  attrs: { clickable: "" },
                  on: {
                    click: function ($event) {
                      return _vm.changeLang("RU")
                    },
                  },
                },
                [
                  _c(
                    "q-item-section",
                    { attrs: { avatar: "" } },
                    [_c("q-icon", { attrs: { name: _vm.getMenuIcon("RU") } })],
                    1
                  ),
                  _c("q-item-section", [
                    _vm._v(_vm._s(_vm.getMenuLabel("RU"))),
                  ]),
                ],
                1
              ),
              _c(
                "q-item",
                {
                  directives: [
                    { name: "close-popup", rawName: "v-close-popup" },
                  ],
                  attrs: { clickable: "" },
                  on: {
                    click: function ($event) {
                      return _vm.changeLang("EN")
                    },
                  },
                },
                [
                  _c(
                    "q-item-section",
                    { attrs: { avatar: "" } },
                    [_c("q-icon", { attrs: { name: _vm.getMenuIcon("EN") } })],
                    1
                  ),
                  _c("q-item-section", [
                    _vm._v(_vm._s(_vm.getMenuLabel("EN"))),
                  ]),
                ],
                1
              ),
              _c(
                "q-item",
                {
                  directives: [
                    { name: "close-popup", rawName: "v-close-popup" },
                  ],
                  attrs: { clickable: "" },
                  on: {
                    click: function ($event) {
                      return _vm.changeLang("DE")
                    },
                  },
                },
                [
                  _c(
                    "q-item-section",
                    { attrs: { avatar: "" } },
                    [_c("q-icon", { attrs: { name: _vm.getMenuIcon("DE") } })],
                    1
                  ),
                  _c("q-item-section", [
                    _vm._v(_vm._s(_vm.getMenuLabel("DE"))),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }