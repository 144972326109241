export default {
  // Footer
  lab: {
    howItWorks: 'Wie es funktioniert',
    howItWorksTextLink: 'https://youtu.be/sbSdN2HR30A',
    youtube: 'https://youtu.be/sbSdN2HR30A',
    faq: 'FAQs',
    blog: 'Unser Blog',
    resources: 'Dokumente',
    contacts: 'Ansprechpartner',
    socialPages: 'Folgen Sie uns',
    language: 'Sprache',
    nameOfCurrentLang: 'Deutsch',
    // The name of the current (translation) language
    sendUsAMessage: 'Eine Nachricht senden'
  }
};