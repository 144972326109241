export default {
  // A contributor's board
  lab: {
    newRequests: 'New Requests',
    pending: 'Pending',
    rejected: 'Rejected',
    todo: 'To Do',
    inProgress: 'In Progress',
    done: 'Done',
    cancelled: 'Cancelled',
    accept: 'Accept',
    estimate: 'Estimate',
    reject: 'Reject',
    update: 'Update',
    rate: 'Rate the client'
  },
  format: {
    formatIntervalWithDays: 'DDd HH:MM',
    formatIntervalWODays: 'HH:MM:SS'
  }
};