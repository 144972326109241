import acsaccount from "./acsaccount.js";
import acsrole from "./acsrole.js";
import login from "./login.js";
import com from "./com.js";
import { smaCalendar, smaCalendarDay } from "./smaCalendar.js";
import { smaProfile } from "./smaProfile.js";
import { references } from "./refsMenu.js";
import { aftAvatar, aftLogo, smaCurrency, comLang, dashboard, analytics } from "./others.js";
import smarequest from "./smarequest.js";
import { customer, smaCustomer } from "./smaCustomer.js";
import { smaReview } from "./smaReview.js";
import smaservice from "./smaservice.js";
import executor from "./executor.js";
import pageFooter from "./pageFooter.js";
import smaFreelance from "./smaFreelance.js";
import { smaAnalytics } from "./smaAnalytics.js";
export default {
  acsaccount: acsaccount,
  acsrole: acsrole,
  login: login,
  com: com,
  references: references,
  smaCalendar: smaCalendar,
  smaCalendarDay: smaCalendarDay,
  aftAvatar: aftAvatar,
  aftLogo: aftLogo,
  smaCurrency: smaCurrency,
  comLang: comLang,
  dashboard: dashboard,
  analytics: analytics,
  smaProfile: smaProfile,
  smaCustomer: smaCustomer,
  customer: customer,
  smaReview: smaReview,
  smarequest: smarequest,
  smaservice: smaservice,
  executor: executor,
  pageFooter: pageFooter,
  smaFreelance: smaFreelance,
  smaAnalytics: smaAnalytics
};