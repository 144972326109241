export default {
  lab: {
    rec: 'Услуги',
    tab: 'Услуги',
    tabPlural: 'Продукты и Услуги',
    service: 'Продукт / Услуга',
    tabMain: 'Основные настройки',
    tabStages: 'Этапы и длительность',
    tabAccs: 'Участники',
    tabExecs: 'Исполнители',
    tabIn: 'Внутренние исполнители',
    tabOut: 'Внешние исполнители',
    name: 'Наименование',
    custname: 'Заказчик',
    note: 'Описание',
    iso: 'Валюта',
    budget: 'Бюджет',
    gen: 'Стандартная',
    lang: 'Владение языками',
    skills: 'Необходимые навыки',
    minrate: 'Минимальный рейтинг',
    genclaim: 'Стандартная заявка (стандартная цена)',
    stansellperiod: 'Реализация (для стандартной заявки)',
    maxclaimval: 'Максимальная (стартовая) цена',
    maxclaimvalinc: 'Рост цены при переторжке',
    rebidcnt: 'Кол-во итераций переторжки',
    valqualscale: 'Критерии выбора победителя',
    scaleprice: 'Цена',
    scalequality: 'Качество',
    calendar: 'Календарь',
    coordagreeperiod: 'Согласование владельцем продукта / координатором',
    tradeperiod: 'Торги',
    rebidperiod: 'Переторжка',
    show_bid_prices: 'Оглашение цен конкурсных заявок',
    manualChoose: 'Ручной выбор исполнителя',
    startconfperiod: 'Подтверждение старта работ',
    accepttestperiod: 'Тестирование',
    deployperiod: 'Архитектурный контроль / развёртывание',
    innerperiod: 'Назначение внутреннего исполнителя',
    collaballow: 'Возможно привлечение соисполнителей',
    acccust: 'Инициаторы (создание заявок, выбор исполнителей, тестирование)',
    servAcc: 'Сотрудник',
    acccoord: 'Владельцы продукта / Координаторы (согласование заявок и выбора исполнителей)',
    accitexp: 'Архитекторы / ИТ-специалисты (архитектурный контроль, развёртывание)',
    accitcoord: 'ИТ-координаторы (настройка продукта / услуги, назначение внутренних исполнителей)',
    url: 'Ссылка',
    rsereqcap: 'Поиск услуг',
    execname: 'Исполнитель',
    framework: 'Договор заключен',
    frameworkDlg: 'Договор / рамочное соглашение заключено',
    wagerate: 'Ставка в час без НДС',
    startRating: 'Начальный рейтинг',
    addRequest: 'Создать заявку',
    stname: 'Статус',
    coordrequired: 'Требуется согласование владельца продукта / координатора',
    coordCond: 'Только при превышении цены на',
    // Убрал лишний пробел, в английской версии его не было
    files: 'Документы и ссылки',
    pickFile: 'Перетащите файл сюда',
    jobPosition: 'Должность',
    requirementsperiod: 'Уточнение требований (для нестандартной заявки)',
    notgenclaimFix: 'Нестандартная заявка (фиксированная стоимость)',
    notgenclaimFact: 'Нестандартная заявка (оплата по факту)',
    claimType: 'Вид заявки',
    payfactLabel: 'Ответственные за согласование фактических трудозатрат исполнителя',
    cust: 'Инициатор',
    coord: 'Владелец продукта / Координатор',
    itcoord: 'ИТ-координатор',
    itspec: 'Архитектор / ИТ-специалист',
    payfactperiod: 'Согласование фактических трудозатрат',
    servFreelanceLabel: 'Размещать заявку на фриланс-платформах',
    isOuterAvailable: 'Доступно для просмотра внешними исполнителями',
    sendHead: 'Ссылка для аккредитации',
    sendText: 'Скопируйте и отправьте эту ссылку, чтобы зарегистрировать и аккредитовать по данной услуге нового исполнителя:'
  },
  act: {
    additional: 'Дополнительно',
    additionalFilterTooltip: 'Указать дополнительные критерии фильтра',
    decline: 'Не интересует',
    accr: 'Аккредитация',
    add: 'Создать продукт / услугу',
    addAcc: 'Добавить сотрудника',
    addExec: 'Добавить исполнителя',
    addLink: 'Добавить ссылку',
    send: 'Передать',
    copy: 'Скопировать в буфер'
  },
  msg: {
    execExist: 'Уже есть исполнитель {execName}',
    curMiss: 'Не указана валюта',
    coordAgreePeriodMiss: 'Не указана длительность согласования владельцем продукта / координатором',
    tradeperiodMiss: 'Не указана длительность торгов',
    rebidperiodMiss: 'Не указана длительность переторжки',
    initwinnerperiodperiodMiss: 'Не указана длительность ручного выбора исполнителя',
    coordwinnerperiodperiodMiss: 'Не указана длительность согласования выбора исполнителя ВП / координатором',
    coordwinnerperiodcondMiss: 'Не указан процент превышения цены',
    startconfperiodMiss: 'Не указана длительность подтверждения старта работ',
    innerperiodMiss: 'Не указана длительность назначения внутреннего исполнителя',
    accepttestperiodMiss: 'Не указана длительность тестирования',
    deployperiodMiss: 'Не указана длительность архитектурного контроля / развёртывания',
    maxclaimvalMiss: 'Не указана максимальная (стартовая) цена заявки',
    maxclaimvalincMiss: 'Не указан рост цены при переторжке',
    rebidcntMiss: 'Не указано количество итераций переторжки',
    stantermMiss: 'Не указан длительность реализации',
    downloadFile: 'Скачивание файла...',
    uploadFile: 'Загрузка файлов...',
    tooBigFile: 'Размер файла слишком большой',
    tooBigFileSize: 'Размер файла {fileName} превышает 16 МБ',
    wagerateMiss: 'Не указана ставка в час',
    requirementsMiss: 'Не указана длительность этапа "Уточнение требований"',
    rebidReqsMiss: 'Этап "Уточнение требований" возможен только в случае, если предусмотрена переторжка',
    payfactMiss: 'Не определены ответственные за согласование трудозатрат исполнителя',
    payfactperiodMiss: 'Не указана длительность согласования фактических трудозатрат',
    linkCopied: 'Ссылка скопирована',
    noSuchService: 'Услуга, по которой запрошена аккредитация, не существует',
    alreadyAccred: 'Вы уже зарегистрированы по услуге "{servName}"'
  },
  smaServiceAccrReq: {
    wageRate: 'Ставка в час без НДС',
    okButton: 'Запросить аккредитацию'
  }
};