export default {
  lab: {
    howItWorks: 'Как это работает',
    howItWorksTextLink: 'https://youtu.be/WFZecfY7aQA',
    youtube: 'https://www.youtube.com/channel/UCllCuNoFU5W74CtPt6Y_J-w',
    faq: 'Вопросы и ответы',
    blog: 'Наш блог',
    resources: 'Документы',
    contacts: 'Контакты',
    socialPages: 'Мы в соц. сетях',
    language: 'Язык',
    nameOfCurrentLang: 'Русский',
    sendUsAMessage: 'Отправить запрос'
  }
};