export var smaAnalytics = {
  reportType: 'Report type',
  btnLoadReport: 'Upload to Excel',
  btnLoadStatistic: 'Form',
  filenameRep1: 'Report by claim quantity',
  filenameRep2: 'Detail report by claims',
  filenameRep3: 'Report by executors',
  filenameRep4: 'Report by economics',
  captionProduct: 'Product',
  captionExecutor: 'Executor',
  captionPeriod: 'Period',
  captionRating: 'Rating',
  errorEmptyParameter: 'Fill in all report parameters',
  errorDtGeLe: 'Start date too big',
  errorDtGeMin: 'Start date too small',
  errorRatingGeLe: 'The minimum rating must be less than the maximum rating'
};