export default {
  // 'Sing in', 'Sign up', 'Send a message' forms
  lab: {
    form: 'Bitte melden Sie sich an',
    frmchngpsw: 'Bitte ändern Sie Ihr Passwort',
    login: 'Benutzerkonto',
    psw: 'Passwort',
    welcome: "Sie haben noch kein Konto auf der Plattform?",
    newpsw: 'Neues Passwort',
    oldpsw: 'Altes Passwort',
    supform: 'Anmeldung für Mitwirkende',
    supfio: 'Nachname Vorname',
    supcompkey: 'Firmencode (geben Sie an, ob Ihnen dieser Code bekannt ist)',
    suppswconf: 'Passwort bestätigen',
    suptext1: 'Indem Sie auf Anmelden klicken, erklären Sie sich mit den',
    suptext2: 'Allgemeinen Geschäftsbedingungen',
    suptext3: 'und der',
    suptext4: 'Datenschutzerklärung',
    suptext6: 'der Smart IT Plattform einverstanden',
    suptext5: 'Brauchen Sie Hilfe?',
    suprefterms: 'https://smartit.digital/documents',
    suprefpolicy: 'https://smartit.digital/documents',
    pswhint: 'Mindestens 8 Zeichen, einschließlich GROSS- und Kleinbuchstaben, und Zahlen',
    pswconf: 'Neues Passwort bestätigen',
    qform: 'Bitte senden Sie uns Ihre Nachricht',
    qlink: 'Senden Sie uns eine Nachricht',
    qcompany: 'Firma',
    qphone: 'Telefon',
    qsubject: 'Betreff',
    qmessage: 'Ihre Nachricht',
    qattachment: 'Eine Datei hierher ziehen und ablegen',
    rpform: 'E-Mail-Adresse'
  },
  act: {
    login: 'Anmelden',
    reg: 'Machen Sie mit!',
    supreg: 'Registrieren',
    chngok: 'Passwort ändern',
    qsend: 'Senden',
    forgetpsw: 'Passwort vergessen?',
    resetpsw: 'Passwort zurücksetzen'
  },
  msg: {
    toauth: "Authentifizierung ist erforderlich",
    invalidpsw: "Bitte überprüfen Sie Ihr Konto oder Passwort",
    supcompnotfound: "Die Firma mit dem von Ihnen angegebenen Code wurde nicht gefunden",
    supchkemailpsw: "Das Passwort sollte nicht mit Ihrer E-Mail übereinstimmen",
    pswchkold: "Bitte überprüfen Sie Ihr Passwort",
    pswchanged: "Ihr Passwort wurde erfolgreich geändert",
    pswchknewold: "Das neue Passwort muss sich von Ihrem bisherigen Passwort unterscheiden",
    pswchknewlogin: "Das Passwort sollte nicht mit Ihrem Konto übereinstimmen",
    pswchkdiff: "Das Bestätigungspasswort ist anders",
    pswchku: "Das von Ihnen eingegebene Passwort entspricht nicht den Anforderungen",
    qsent: "Die Nachricht wurde gesendet",
    qsending: "Senden der Nachricht",
    qsenderror: "Die Nachricht konnte nicht gesendet werden",
    rpresetok: "Ein neues Passwort wurde an Ihre E-Mail gesendet",
    suprefinvalid: 'Falscher Link'
  }
};