export default {
  // Footer
  lab: {
    howItWorks: 'How it works',
    howItWorksTextLink: 'https://youtu.be/sbSdN2HR30A',
    youtube: 'https://youtu.be/sbSdN2HR30A',
    faq: 'FAQs',
    blog: 'Our blog',
    resources: 'Resources',
    contacts: 'Contacts',
    socialPages: 'Follow us',
    language: 'Language',
    nameOfCurrentLang: 'English',
    // The name of the current (translation) language
    sendUsAMessage: 'Send us a message'
  }
};