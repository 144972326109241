export var aftAvatar = {
  // ADMINISTRATOR mode, administrating languages, currencies, etc.
  tooBigAvatar: 'Das Bild ist zu groß. Bitte wählen Sie eine andere Datei',
  avatarInvalidExtension: 'Nur Dateien mit den folgenden Endungen sind erlaubt: jpg, jpeg, png'
};
export var aftLogo = {
  tooBigLogo: 'Das Bild ist zu groß. Bitte wählen Sie eine andere Datei',
  logoInvalidExtension: 'Nur Dateien mit den folgenden Endungen sind erlaubt: jpg, jpeg, png'
};
export var smaCurrency = {
  lab: {
    rec: '',
    tab: 'Währung'
  },
  label: {
    tabCaption: 'Währung',
    name: 'Name',
    code: 'Code',
    symbol: 'Symbol',
    id: 'XXXXXXXXXXXXID',
    nameLoc: 'XXXXXXXXXXXXXName Lokalisierungscode'
  }
};
export var comLang = {
  lab: {
    rec: '',
    tab: 'Sprache'
  },
  label: {
    tabCaption: 'Sprache',
    name: 'Name',
    code: 'Code',
    active: 'Aktiv',
    localeCode: 'Regionaler Code',
    defShortDateFmt: 'Datumsformat',
    defLongDateFmt: 'Datums- und Zeitformat'
  }
};
export var dashboard = {
  label: {
    tabCaption: 'Analytik-Benutzer'
  },
  lab: {
    name: 'Analytik-Benutzer',
    link: 'URL',
    dashboardSave: 'Speichern'
  }
};
export var analytics = {
  lab: {
    tab: 'Analytik'
  }
};