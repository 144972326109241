import "core-js/modules/es6.regexp.split.js";
import "core-js/modules/es6.array.filter.js";
import "core-js/modules/es6.regexp.replace.js";
import "core-js/modules/es6.string.includes.js";
import "core-js/modules/es7.array.includes.js";
import "core-js/modules/es6.string.ends-with.js";
import "core-js/modules/es6.array.slice.js";
import "core-js/modules/es6.object.to-string.js";
import { curAccount } from 'pages/Access/acsaccount';
import { i18n } from 'boot/i18n';
export default {
  mixins: [],
  methods: {
    localizeTextFromServer: function localizeTextFromServer(text) {
      var _this = this;
      if (!text) {
        return '';
      }
      // сообщение в общем случае может дробиться на несколько составных частей.
      var txtArr = text.split(/(?=\$t|\$tc)/g);
      var res = '';
      txtArr.forEach(function (itm, idx, arr) {
        res += _this._localizeTextFromServerOne(itm);
      });
      return res;
    },
    _localizeTextFromServerOne: function _localizeTextFromServerOne(text) {
      // локализация сообщения, записанного в специальном формате
      // (например, на сервере хранятся комментарии системы по актуализации статуса заявки - и они будут локализовываться)
      // сообщение должно быть записано в нескольких строках, например:
      /*
      $t
      smarequest.msg.platformCommentActualizeStatus
      55
      2020-08-29T05:55:00
      */

      // первая строка - магическая и она === '$t' или '$tc' для использования затем функции $t(...) или $tc(...) соответственно
      if (!text || !/\$t|\$tc\r?\n/.test(text)) {
        return text;
      }

      // вторая строка - код локализации для клиентской функции $t, например === 'smarequest.msg.platformCommentActualizeStatus'
      var values = text.split(/\r?\n/);
      var code = values[1];

      // остальные строки - параметры шаблона, который лежит по коду локализации, например,
      // для шаблона === 'Исполнение: {0}%, ожидаемый срок: {1:dt}.' будет две строки,
      // в первой - процент исполнения (целое число в виде строки),
      // во второй - дата и время, для шаблона типа {N:dt} его надо хранить как UTC в ISO-формате
      // 'YYYY-MM-DDTHH:mm:ss.nnnnnn+00:00' (а также для поддержки старых данных 'YYYY-MM-DDTHH:mm:ss')

      // надо удалить возможные пустые элементы
      values = values.filter(function (v) {
        return v !== '';
      });
      if (values[0] === '$tc') {
        // у $tc должен быть только 1 параметр, специальное форматирование не требуется
        values.splice(0, 2); // удалить 2 первых элемента
        return this.$tc(code, values);
      }
      values.splice(0, 2); // удалить 2 первых элемента
      return this._formatString(this.$t(code), values);
    },
    // Заменить в строке s вхождения {0}, {1}, ...{N} на соответствующие номеру элементы массива args[0...N]
    // Специальные форматы: {N:dt} - дата/время в текущем часовом поясе и локализации пользователя
    _formatString: function _formatString(s, args) {
      // Фабрика функции для Regexp замены
      function makeReplacer(formatValueFunction /* Функция для форматирования отдельного значения */) {
        return function (match, number) {
          var s = args[number];
          if (typeof s === 'undefined') {
            return match;
          } // если значения подстановки нет, то оставить исходную подстроку шаблона
          if (formatValueFunction) {
            s = formatValueFunction(s);
          }
          return s;
        };
      }
      s = s.replace(/{(\d+)}/g, makeReplacer()); // формат {N}, где N - целое число, порядковый номер параметра (начиная с 0, может быть многозначным)
      s = s.replace(/{(\d+):dt}/g, makeReplacer(this._isoDateTimeStringToUserLocaleWoSec)); // формат {N:dt} для даты и времени UTC в ISO-формате
      return s;
    },
    // Строку с датой-временем UTC в ISO-формате 'YYYY-MM-DDTHH:mm:ss.nnnnnn+00:00' (а также для поддержки старых данных 'YYYY-MM-DDTHH:mm:ss')
    // преобразовать в строку с датой-временем в часовом поясе профиля пользователя (а не компьютера) и в формате пользователя
    _isoDateTimeStringToUserLocaleWoSec: function _isoDateTimeStringToUserLocaleWoSec(sDateTimeIso) {
      console.assert(typeof sDateTimeIso === 'string');
      var dt;
      if (sDateTimeIso.includes('+')) {
        if (!sDateTimeIso.endsWith('+00:00')) throw new Error('_isoDateTimeStringToUserLocaleWoSec: Дата/время должны заканчиваться на +00:00');
        dt = _iso2bro(sDateTimeIso);
      } else {
        // поддержка старых данных без +00:00
        dt = new Date(sDateTimeIso); // получается часовой пояс локальной машины, но время как было указано...
        // (т.е. побочный эффект - добавился часовой пояс локальной машины)
        // а поскольку был указан UTC, потом добавился пояс лок. машины, то осталось только добавить часовой пояс пользователя
        dt = _addHours(dt, getCurrentUserGmtPlus()); // сломаем дату как надо
      }
      return this.bro2localeWoSec(dt); // преобразуем в строку сломанную дату
    },
    // ==============================================================================================
    // ==============================================================================================
    // ==============================================================================================
    // Текущее время со смещением часового пояса под текущего пользователя
    // [BROKEN GMT] - вместо GMT профиля пользователя будет GMT локальной машины
    getNowBro: function getNowBro() {
      return _localMachineGmtDateTimeToBro(new Date());
    },
    iso2bro: function iso2bro(s) {
      return _iso2bro(s);
    },
    // // округлить до даты, т.е. отбросить компонент времени
    // truncBro (date) {
    //   if (!date) return null
    //   console.assert(isInstanceOfDate(date), date)
    //   return new Date(date.toDateString())
    // },
    // ========================================================================================================
    // Преобразует "сломанную" дату в локализованную строку _без времени_(!).
    // Сломанная дата - это дата в часовом поясе из профиля пользователя,
    // но с указанным неправильно часовым поясом локальной машины.
    bro2localeWoTime: function bro2localeWoTime(date, format) {
      if (date === null) {
        return '';
      }
      console.assert(isInstanceOfDate(date));
      var fmt = format || this.$t('com.dateFormat');
      fmt = fmt.toUpperCase();
      if (fmt.indexOf('DD') > -1) {
        fmt = fmt.replace('DD', ('0' + date.getDate()).slice(-2));
      }
      if (fmt.indexOf('MM') > -1) {
        fmt = fmt.replace('MM', ('0' + (date.getMonth() + 1)).slice(-2));
      }
      if (fmt.indexOf('YYYY') > -1) {
        fmt = fmt.replace('YYYY', date.getFullYear());
      }
      if (fmt.indexOf('YY') > -1) {
        fmt = fmt.replace('YY', date.getFullYear().slice(-2));
      }
      return fmt;
    },
    bro2localeWoSec: function bro2localeWoSec(date) {
      return this.bro2locale(date, i18n.t('com.dateTimeWoSecFormat'));
    },
    // Преобразует "сломанную" дату со временем в локализованную строку.
    // Сломанная дата - это дата в часовом поясе из профиля пользователя,
    // но с указанным неправильно часовым поясом локальной машины.
    bro2locale: function bro2locale(date, format) {
      if (date === null) {
        return '';
      }
      console.assert(isInstanceOfDate(date));

      // let date = this.aftLocalDateTime(utcdate)
      var fmt = format || this.$t('com.dateTimeFormat');
      fmt = this.bro2localeWoTime(date, fmt);
      if (fmt.indexOf('HH') > -1) {
        fmt = fmt.replace('HH', ('0' + date.getHours()).slice(-2));
      }
      if (fmt.indexOf('MI') > -1) {
        fmt = fmt.replace('MI', ('0' + date.getMinutes()).slice(-2));
      }
      if (fmt.indexOf('MM') > -1) {
        fmt = fmt.replace('MM', ('0' + date.getMinutes()).slice(-2));
      }
      if (fmt.indexOf('SS') > -1) {
        fmt = fmt.replace('SS', ('0' + date.getSeconds()).slice(-2));
      }
      return fmt;
    },
    // Добавляет недостающие елементы из текущей даты, расширяет год, убирает пробелы, добавляет нули ...
    // Если не получается отпарсить, возвращает null
    // Для даты:
    //   - понимает разделители: '.' '/' '\' '-' ','
    //   - нет разделителей - считаем, что ввели только дату
    //   - один разделитель - дата + месяц или месяц + дата в зависимости от формата
    //   - два разделителя - парсим по формату
    // Для времени:
    //   - если задано, то всегда через пробел от даты, разделитель элементов: ':'
    //   - незаданные элементы добиваем нулями
    localeWoTime2bro: function localeWoTime2bro(datestr, format) {
      if (datestr === null) {
        return null;
      }
      console.assert(typeof datestr === 'string');
      var fmt = format || this.$t('com.dateFormat');
      fmt = fmt.toUpperCase();
      var sep = fmt.indexOf('.') > -1 ? '.' : null;
      if (!sep) {
        sep = fmt.indexOf('-') > -1 ? '-' : null;
      }
      if (!sep) {
        sep = fmt.indexOf('/') > -1 ? '/' : null;
      }
      if (!sep) {
        sep = fmt.indexOf('\\') > -1 ? '\\' : null;
      }
      if (!sep) {
        return undefined;
      }
      var y, m, d;
      var dt = new Date();

      // Возвращает индекс периода per в массиве элементов распиленной по разделителю sep строки формата
      function idxYMD(per) {
        var arr = fmt.split(sep);
        for (var i = 0; i < arr.length; i++) {
          if (arr[i].indexOf(per) >= 0) {
            return i;
          }
        }
        return -1;
      }
      // Возвращает элемент ном idx из datestr с учетом возможных разделителей
      function elbyidx(idx) {
        var iel = 0,
          istart = 0;
        for (var i = 0; i < datestr.length && iel <= idx; i++) {
          if (datestr[i] === '.' || datestr[i] === '/' || datestr[i] === '\\' || datestr[i] === '-' || datestr[i] === ',' || datestr[i] === ' ') {
            if (iel === idx) {
              return datestr.slice(istart, i);
            } else {
              iel++;
              istart = i + 1;
            }
          }
        }
        if (iel === idx) {
          return datestr.slice(istart);
        }
        return undefined;
      }

      // Разрешим при вводе все разделители, а не только из строки формата
      d = elbyidx(idxYMD('DD'));
      m = elbyidx(idxYMD('MM'));
      y = elbyidx(idxYMD('YY'));
      if (!d) {
        d = dt.getDate();
      } else {
        d = parseInt(d);
        if (d < 0 || d > 31) return undefined;
      }
      if (!m) {
        m = dt.getMonth();
      } else {
        if (m < 0 || m > 12) return undefined;
        m = parseInt(m) - 1;
      }
      if (!y) {
        y = dt.getFullYear();
      } else {
        if (y.length === 1) {
          y = 2020 + parseInt(y);
        }
        if (y.length === 2) {
          y = 2000 + parseInt(y);
        }
        if (y.length === 3 || y.length > 4) return undefined;
        if (y < 1900 || y > 2100) return undefined;
      }
      return new Date(y, m, d);
    },
    // На входе строка с локализованными датой и временем.
    // Преобразовать в тип Date с локальным часовым поясом, но пересчитанным для часового пояса пользователя
    // (т.е. в сломанное "bro[ken]" дату-время).
    locale2bro: function locale2bro(datetimestr, format) {
      if (datetimestr === null) {
        return null;
      }
      console.assert(typeof datetimestr === 'string', datetimestr);
      var fmt = format || this.$t('com.dateTimeFormat');
      // несколько пробелов в один пробел
      fmt = fmt.toUpperCase().replace(/  +/g, ' ');
      var dfmt,
        dstr,
        tstr,
        h = 0,
        mi = 0,
        s = 0;
      // несколько пробелов в один пробел
      datetimestr = datetimestr.replace(/  +/g, ' ');
      if (fmt.split(' ')[0].indexOf('HH') < 0) {
        dfmt = fmt.split(' ')[0];
        dstr = datetimestr.split(' ')[0];
        tstr = datetimestr.split(' ')[1];
      } else {
        dfmt = fmt.split(' ')[1];
        dstr = datetimestr.split(' ')[1];
        tstr = datetimestr.split(' ')[0];
      }
      var dt = this.localeWoTime2bro(dstr, dfmt);
      if (!dt) {
        return dt;
      }
      if (tstr) {
        h = tstr.split(':')[0];
        mi = tstr.split(':')[1];
        s = tstr.split(':')[2];
      }
      if (h) {
        h = parseInt(h);
        if (h < 0 || h > 24) return undefined;
        dt.setHours(h);
      }
      if (mi) {
        mi = parseInt(mi);
        if (mi < 0 || mi > 60) return undefined;
        dt.setMinutes(mi);
      }
      if (s) {
        s = parseInt(s);
        if (s < 0 || s > 60) return undefined;
        dt.setSeconds(s);
      }
      return dt;
    }
  }
};

// Проверить, что объект имеет тип даты JavaScript
export function isInstanceOfDate(o) {
  return Object.prototype.toString.call(o) === '[object Date]'; // надежнее, чем (o instanseof Date)
}

// Из сервера (питона) приходят дата и время в формате строки JSON в ISO-формате
// (например: "2020-08-11T16:37:05+00:00", "2020-08-11T16:37:05.123456+00:00").
// Преобразовать в тип Date с часовым поясом из профиля пользователя.
// НА САМОМ ДЕЛЕ часовой пояс останется локальным (сломанным), что типично для JavaScript, но время будет смещено.
// Запрещено:
//   "2020-08-11T16:37:05+03:00" - не в UTC.
//   "2020-08-11T16:37:05" - наивное представление.
//   "2020-08-11T16:37:05Z" - UTC, но в другом обозначении.
//   "Wed, 21 Oct 2020 16:37:05 GMT"
//   Без буквы 'T'.
function _iso2bro(s) {
  if (!s) return null;
  console.assert(typeof s === 'string');
  // s = s.replace('Z', '+00:00')
  console.assert(s.endsWith('+00:00') && s[10] === 'T', 'Дата/время "' + s + '" нужно передавать в ISO-формате со смещением +00:00');
  var d = new Date(s); // в локальном часовом поясе
  d = _localMachineGmtDateTimeToBro(d); // в часовом поясе профиля пользователя (если он не гость)
  return d;
}

// Пересчет даты-времени локального браузерного часового пояса
// в дату-время текущего пользователя со сломанным часовым поясом
// (пояс останется от локальной машины, но должен бы быть от профиля).
// Для пользователя-гостя - не пересчитывать.
export { _iso2bro as iso2bro };
function _localMachineGmtDateTimeToBro(localMachineGmtDateTime) {
  console.assert(isInstanceOfDate(localMachineGmtDateTime));
  return _addHours(localMachineGmtDateTime, getCurrentUserGmtPlus() - getLocalMachineGmtPlus());
}

// смещение часового пояса в часах и долях часа для текущего пользователя
// для зарегистрированных пользователей, нужно брать из профиля пользователя,
// а для гостя - из локального компьютера (насколько позволяет js)
export function getCurrentUserGmtPlus() {
  var accData = curAccount();
  // console.log(accData)
  return accData ? accData.gmtPlus : getLocalMachineGmtPlus();
}

// смещение часового пояса в часах и долях часа для локального компьютера
// использовать только для гостей!
// для зарегистрированных пользователей, нужно брать из профиля пользователя
export function getLocalMachineGmtPlus() {
  // можно было из moment, но сойдет и этот метод - попроще и поглючней, т.к. это редко будет использоваться
  return -new Date().getTimezoneOffset() / 60;
}

// Сломанное дата/время преобразовать в iso-строку UTC формата "2020-10-28T15:08:33.781+00:00".
// Как правило, милисекунды должны быть нулевыми.
export function bro2iso(date) {
  if (!date) return null;
  console.assert(isInstanceOfDate(date));
  // Сломанную дату с локальным часовым поясом в несломанную с тем же поясом.
  var localMachineGmtDate = _addHours(date, getLocalMachineGmtPlus() - getCurrentUserGmtPlus());
  return localMachineGmtDate.toISOString().replace('Z', '+00:00');
}

// ==================================================== Дата без времени ===============================

// // Преобразование даты без времени ISO в локализованную для пользователя.
// // На входе строка в ISO-формате "YYYY-MM-DD" (дата без времени, например, производственный календарь).
// // На выходе та же строка в формате текущего языка интерфейса, например, в формате "DD/MM/YY".
// // Всякие смещения часовых поясов могут привести к изменению на другую дату, поэтому они исключаются.
// export function isoWoTime2locale (isoWoTime, format) {
//   console.assert(typeof isoWoTime === 'string', isoWoTime)
//   console.assert(isoWoTime.length === 10, isoWoTime)

//   const arr = isoWoTime.split('-')
//   const y = parseInt(arr[0])
//   const m = parseInt(arr[1])
//   const d = parseInt(arr[2])

//   const fmt = (format || i18n.t('com.dateFormat')).toUpperCase()
//   const result = fmt
//     .replace('DD', ('0' + d).slice(-2))
//     .replace('MM', ('0' + m).slice(-2))
//     .replace('YYYY', y + '')
//     .replace('YY', (y + '').slice(-2))

//   return result
// }

// // Преобразование даты без времени локализованной в ISO.
// // На входе строка в формате текущего языка интерфейса, например, в формате "DD/MM/YY".
// // На выходе строка в ISO-формате "YYYY-MM-DD".
// // Всякие смещения часовых поясов могут привести к изменению на другую дату, поэтому они исключаются.
// export function locale2isoWoTime (localeWoTime, format) {
//   console.assert(typeof localeWoTime === 'string')
//   const fmt = (format || i18n.t('com.dateFormat')).toUpperCase()

//   let y
//   let p = fmt.indexOf('YYYY')
//   if (p === -1) {
//     p = fmt.indexOf('YY')
//     y = localeWoTime.substring(p, p + 2)
//   } else {
//     y = localeWoTime.substring(p, p + 4)
//   }

//   p = fmt.indexOf('MM')
//   let m = localeWoTime.substring(p, p + 2)
//   p = fmt.indexOf('DD')
//   let d = localeWoTime.substring(p, p + 2)

//   y = parseInt(y)
//   if (y < 100) y += 2000
//   m = parseInt(m)
//   d = parseInt(d)

//   return 'YYYY-MM-DD'
//     .replace('YYYY', y)
//     .replace('MM', ('0' + m).slice(-2))
//     .replace('DD', ('0' + d).slice(-2))
// }

// Преобразование даты без времени ISO в "сломанную" дату-время для пользователя (12:00 часов).
// На входе строка в ISO-формате "YYYY-MM-DD" (дата без времени), например:
// - производственный календарь - здесь дата подходит для нескольких часовых поясов, а время указывается в других полях.
// - срок альтернативного предложения исполнителя по заявке - здесь дата означает момент времени, но точность указания плюс-минус сутки,
//   поэтому мы должны пренебречь часовыми поясами.
// На выходе тип Date, с фиктивным временем 12:00 по часовому поясу локальной машины,
// а это для bro-дат и означает 12:00 времени в часовом поясе текущего пользователя.
export function isoWoTime2bro12(isoWoTime) {
  console.assert(typeof isoWoTime === 'string', isoWoTime);
  console.assert(isoWoTime.length === 10, isoWoTime);
  return new Date(isoWoTime + 'T12:00'); // получается 12:00 по часовому поясу локальной машины
}
function _addHours(utcBreakedDateTime, hoursToAdd) {
  var hours = utcBreakedDateTime.getHours() + hoursToAdd;
  // надо не сломать входной параметр, который передается по ссылке, поэтому сделаем копию
  var tmp = new Date(utcBreakedDateTime);
  tmp.setHours(hours); // возвращает целое число, которое нам теперь не нужно
  return tmp;
}