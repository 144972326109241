export default {
  // Administrating user roles
  lab: {
    object: "Configuring the access role",
    cardTab: "Configuring the access role",
    type: "Type of role",
    objcode: "Code of the business object",
    objcaption: "Name of the business object",
    permlab: "Type of access",
    actperm: "To set the access: ",
    permR: "Viewing only",
    permW: "Modification",
    permA: "Administrator",
    permN: "No access"
  },
  act: {},
  msg: {
    permdone: "Access is set"
  }
};