export default {
  // PRODUCTS & SERVICES mode, configuring a product / service
  lab: {
    rec: 'Dienstleistungen',
    tab: 'Dienstleistungen',
    tabPlural: 'Produkte & Dienstleistungen',
    service: 'Produkt / Dienstleistung',
    tabMain: 'Allgemeine Einstellungen',
    tabStages: 'Schritte und Dauer',
    tabAccs: 'Akteure',
    tabExecs: 'Mitwirkende',
    tabIn: 'Interne Mitwirkende',
    tabOut: 'Ausgelagerte Mitwirkende',
    name: 'Name',
    custname: 'Klient',
    note: 'Beschreibung',
    iso: 'Währung',
    budget: 'Budget',
    gen: 'Standard',
    lang: 'Sprachen',
    skills: 'Fertigkeiten',
    minrate: 'Mindestbewertung',
    genclaim: 'Standardantrag (Standartpreis)',
    stansellperiod: 'Implementierung (für Standardanträge)',
    maxclaimval: 'Maximaler (Anfangs-)Preis',
    maxclaimvalinc: 'Preiserhöhung für Nachbieten',
    rebidcnt: 'Iterationen von Neuausschreibungen',
    valqualscale: 'Auswahlkriterien für den Gewinner',
    scaleprice: 'Preis',
    scalequality: 'Qualität',
    calendar: 'Kalender',
    coordagreeperiod: 'Genehmigung durch einen Produktbesitzer / Koordinator',
    tradeperiod: 'Gebotsabgabe',
    rebidperiod: 'Nachbieten',
    show_bid_prices: 'Bekanntgabe der Angebotspreise',
    manualChoose: 'Manuelle Auswahl eines Mitwirkenden',
    startconfperiod: 'Bestätigung des Arbeitsbeginns',
    accepttestperiod: 'Prüfung',
    deployperiod: 'Architektonische Kontrolle / Bereitstellung',
    innerperiod: 'Zuweisung einer internen Mitwirkenden',
    collaballow: 'Die Anwerbung von Mitwirkenden ist erlaubt',
    acccust: 'Antragsteller (Erstellen von PBIs und Anträgen, Auswahl der Mitwirkenden, Prüfung)',
    servAcc: 'Verantwortlich',
    acccoord: 'Produktbesitzer / Koordinatoren (Genehmigung von PBIs & Anträgen und ausgewählten Mitwirkenden)',
    accitexp: 'Architekten / IT-Spezialisten (Architektonische Kontrolle, Bereitstellung)',
    accitcoord: 'IT-Koordinatoren (Verwaltung des Produkts / der Dienstleistung, Zuweisung interner Mitwirkender)',
    url: 'URL',
    rsereqcap: 'Dienstleistungen finden',
    execname: 'Mitwirkender',
    framework: 'Vereinbarung abgeschlossen',
    frameworkDlg: 'Vertrag / Rahmenvereinbarung abgeschlossen',
    wagerate: 'Stundensatz ohne MWST',
    startRating: 'Anfängliche Bewertung',
    addRequest: 'PBI / Antrag erstellen',
    stname: 'Status',
    coordrequired: 'Genehmigung des Produktbesitzers / Koordinators erforderlich',
    coordCond: 'Nur wenn der Preis überschritten wird um',
    files: 'Dokumente und Links',
    pickFile: 'Eine Datei hierher ziehen und ablegen',
    jobPosition: 'Position',
    requirementsperiod: 'Klärung der Anforderungen (Nicht-Standardanträge)',
    notgenclaimFix: 'Nicht-Standardantrag (Festpreis)',
    notgenclaimFact: 'Nicht-Standardantrag (Zeit & Material)',
    claimType: 'Art des Antrags',
    payfactLabel: 'Verantwortlich für die Genehmigung der tatsächlichen Arbeitskosten des Mitwirkenden',
    cust: 'Antragsteller',
    coord: 'Produktbesitzer / Koordinator',
    itcoord: 'IT-Koordinator',
    itspec: 'Architekt / IT-Spezialist',
    payfactperiod: 'Genehmigung der tatsächlichen Arbeitskosten',
    servFreelanceLabel: 'Anträge auf freiberuflichen Plattformen einreichen',
    isOuterAvailable: 'Sichtbar für externe Mitwirkende',
    sendHead: 'Link zur Akkreditierung',
    sendText: 'Kopieren Sie diesen Link und senden Sie ihn an einen neuen Mitwirkenden, damit er sich anmelden und für diesen Dienstleistung akkreditieren könnte:'
  },
  act: {
    additional: 'Mehr',
    additionalFilterTooltip: 'Geben Sie zusätzliche Filterkriterien an',
    decline: 'Nicht interessiert',
    accr: 'Akkreditierung',
    add: 'Neues Produkt / Dienstleistung erstellen',
    addAcc: 'Verantwortlichen hinzufügen',
    addExec: 'Mitwirkenden hinzufügen',
    addLink: 'Link hinzufügen',
    send: 'Teilen',
    copy: 'In die Zwischenablage kopieren'
  },
  msg: {
    execExist: 'Es gibt bereits den Mitwirkenden {execName}',
    curMiss: 'Die Währung ist nicht festgelegt',
    coordAgreePeriodMiss: 'Die Dauer der Genehmigung durch einen PB / Koordinator ist nicht festgelegt',
    tradeperiodMiss: 'Die Dauer der Gebotsabgabe ist nicht festgelegt',
    rebidperiodMiss: 'Die Dauer der Nachbietung ist nicht festgelegt',
    initwinnerperiodperiodMiss: 'Die Dauer der manuellen Auswahl eines Mitwirkenden ist nicht festgelegt',
    coordwinnerperiodperiodMiss: 'Die Dauer der Genehmigung eines PB / Koordinators für einen ausgewählten Mitwirkenden ist nicht festgelegt',
    coordwinnerperiodcondMiss: 'Die Preisüberschreitung wird nicht festgelegt',
    startconfperiodMiss: 'Die Dauer der Bestätigung des Arbeitsbeginns ist nicht festgelegt',
    innerperiodMiss: 'Die Dauer der Zuweisung eines internen Mitwirkenden ist nicht festgelegt',
    accepttestperiodMiss: 'Die Dauer der Prüfung ist nicht festgelegt.',
    deployperiodMiss: 'Die Dauer der Architektonische Kontrolle / Bereitstellung ist nicht festgelegt',
    maxclaimvalMiss: 'Der maximale (Anfangs-)Preis ist nicht festgelegt.',
    maxclaimvalincMiss: 'Die Erhöhung des Nachbietungspreises ist nicht festgelegt',
    rebidcntMiss: 'Die Anzahl der Durchläufe des Nachbietens ist nicht festgelegt',
    stantermMiss: 'Die Dauer der Implementierung ist nicht festgelegt.',
    downloadFile: 'Die Datei wird gerade heruntergeladen...',
    uploadFile: 'Die Dateien werden gerade hochgeladen...',
    tooBigFile: 'Die Dateigröße ist zu groß',
    tooBigFileSize: 'Die Datei {fileName} ist größer als 16 MB',
    wagerateMiss: 'Der Stundensatz ist nicht festgelegt',
    requirementsMiss: 'Die Dauer der Klärung der Anforderungen ist nicht festgelegt',
    rebidReqsMiss: 'Die Klärung der Anforderungen ist nur möglich, wenn ein Nachbieten durchgeführt wird',
    payfactMiss: 'Die Verantwortlichen für die Genehmigung der Arbeitskosten des Auftragnehmers sind nicht festgelegt',
    payfactperiodMiss: 'Die Dauer der Genehmigung der tatsächlichen Arbeitskosten ist nicht festgelegt',
    linkCopied: 'Link kopiert',
    noSuchService: 'Die Dienstleistung, für die die Akkreditierung beantragt wurde, existiert nicht',
    alreadyAccred: 'Sie sind bereits für die Dienstleistung "{servName}" akkreditiert'
  },
  smaServiceAccrReq: {
    wageRate: 'Stundensatz ohne MWST',
    okButton: 'Akkreditierung beantragen',
    needAgreement: 'Bitte schließen Sie die erforderlichen Vereinbarungen ab, um an der Gebotsabgabe teilzunehmen'
  }
};