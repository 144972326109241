export var smaProfile = {
  lab: {
    tab: '',
    rec: ''
  },
  // перевод не требуется
  addInfo: 'Дополнительная информация',
  kind: 'Тип исполнителя',
  customerName: 'Компания',
  // внутреннего исполнителя
  jobPosition: 'Должность',
  // внутреннего исполнителя
  legalFormOoo: 'Общество с ограниченной ответственностью',
  legalFormAo: 'Акционерное общество',
  legalFormOthers: 'Другое',
  kindP: 'Физическое лицо',
  kindG: 'Виртуальная команда',
  kindF: 'Юридическое лицо',
  nameP: 'Фамилия Имя',
  nameG: 'Название виртуальной команды',
  nameF: 'Наименование юридического лица',
  email: 'E-mail',
  phone: 'Телефон',
  socialProfile: 'Профиль в соц. сети',
  // для компании и виртуальной команды: socialProfile: 'Сайт',

  portfolio: 'Портфолио',
  gmtPlus: 'Часовой пояс',
  countryP: 'Страна регистрации',
  // Гражданство
  countryG: 'Страна регистрации',
  countryF: 'Страна регистрации',
  note: 'Краткая информация',
  legalForm: 'Правовая форма',
  resume: 'Навыки',
  langs: 'Владение языками',
  lang: 'Язык интерфейса',
  save: 'Сохранить',
  savedSuccess: 'Профиль успешно сохранён',
  actReviews: 'Отзывы',
  sertificats: 'Свидетельства и сертификаты'
};