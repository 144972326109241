export default {
  // ADMINISTRATOR mode, administrating users, companies and other dictionaries
  menu: {
    account: 'Benutzer',
    role: 'Rollen',
    logout: 'Abmelden',
    newpsw: 'Passwort ändern',
    company: 'Firmen',
    cust: 'Klienten',
    administrator: 'Administrator',
    request: 'PBIs & Anträge',
    dblocale: 'Übersetzungen der Benutzeroberfläche',
    querysubject: 'Betreff der Nachricht'
  },
  lab: {
    messerr: 'Fehler',
    messinfo: 'Informationen',
    messconf: 'Aktion bestätigen',
    addrec: ' (einen Eintrag hinzufügen)',
    updrec: ' (den Eintrag bearbeiten',
    viewrec: ' (den Eintrag ansehen)',
    captcardAdd: 'Neuer Eintrag',
    captcardUpdate: 'Bearbeiten',
    captcardView: 'Ansehen',
    captcardTab: 'Relevante Einträge',
    captcardTabAddle: 'Gelöschte Einträge',
    captcardList: 'Auswählen',
    captcardRse: 'Datenbankfilter',
    captcardRseAddle: 'Datenbankfilter (gelöscht)',
    captcardHistory: 'Änderungshistorie des ID-Eintrags: {id}',
    // The text in brackets should not be translated!
    hcolId: 'Stempel-ID',
    hcolActual: 'Relevanz',
    hcolOper: 'Aktion',
    hcolStamp: 'Wann',
    hcolUser: 'Wer',
    hoper: {
      I: 'Erstellt',
      U: 'Geändert',
      D: 'Gelöscht'
    },
    from: 'von',
    to: 'bis',
    Yes: 'Ja',
    No: 'Nein'
  },
  act: {
    actOk: 'Ausführen',
    actCan: 'Abbrechen',
    actReturn: 'Zurück',
    actSelect: 'Ausführen',
    actAdd: 'Einen neuen Eintrag hinzufügen',
    actUpdate: 'Aktualisieren',
    actView: 'Detailliert',
    actDelete: 'Löschen',
    actFind: 'Tabellensuche',
    actDeleteP: 'Ausgewählte Einträge dauerhaft löschen',
    actClearRec: 'Die Karte löschen',
    actFreshAddleF: 'Aktuelle Einträge anzeigen',
    actFreshAddleA: 'Gelöschte Einträge anzeigen',
    actRepairAddle: 'Ausgewählte Einträge wiederherstellen',
    actHistory: 'Änderungshistorie',
    actExcel: 'Zu Excel exportieren',
    actFilter: 'Filterkarte öffnen',
    actDeleteM: 'Ausgewählte Einträge löschen',
    actSwitchMarksOn: 'Auswahl einschalten',
    actSwitchMarksOff: 'Auswahl ausschalten',
    actExtActs: 'Zusätzliche Funktionen',
    save: 'Speichern',
    cancel: 'Abbrechen',
    select: 'Ausführen',
    add: 'Einen neuen Eintrag hinzufügen',
    view: 'Anzeigen',
    update: 'Ausgewählte Einträge bearbeiten',
    delete: 'Ausgewählte Einträge löschen',
    choice: 'Auswählen',
    updateOne: 'Aktualisieren',
    deleteOne: 'Löschen',
    excel: 'Zu Excel exportieren',
    deleteP: 'Ausgewählte Einträge dauerhaft löschen',
    repairAddle: 'Ausgewählte Einträge wiederherstellen',
    menuFresh: 'Zu den aktuellen Einträgen',
    menuAddle: 'Zu den gelöschten Einträgen',
    history: 'Änderungshistorie',
    historyhint: 'Änderungshistorie anzeigen',
    find: 'Suchen',
    yes: 'Ja',
    no: 'Nein',
    auth: 'Authentifizierung',
    addFiles: 'Eine Datei hierher ziehen und ablegen'
  },
  msg: {
    reqauth: 'Authentifizierung ist erforderlich',
    recSavedSuccess: 'Erfolgreich gespeichert',
    confirmDelete: 'Ausgewählte Einträge löschen?',
    chkRec: 'Das Formular ist falsch ausgefüllt',
    notFound: 'Nicht gefunden',
    btnClose: 'Schließen',
    btnCancel: 'Abbrechen',
    btnOk: 'OK',
    btnSave: 'Speichern',
    btnApprove: 'Fortfahren',
    fillAllMandatoryFields: 'Bitte füllen Sie alle erforderlichen Felder aus',
    fillMandatoryField: 'Dieses Feld ist erforderlich',
    fillMandatoryStringField: 'Dieses Feld ist erforderlich',
    fillMandatoryIntField: 'Dieses Feld kann nur Zahlen enthalten',
    fillMandatoryIntFieldBetween: 'Bitte geben Sie eine Zahl zwischen {minValue} und {maxValue} ein',
    // The text in brackets should not be translated!
    fillNumFieldBetween: 'Bitte geben Sie eine Zahl zwischen {minValue} und {maxValue} ein',
    // The text in brackets should not be translated!
    fillNumFieldGT: 'Bitte geben Sie eine Zahl größer als {minValue} ein',
    // The text in brackets should not be translated!
    uploadFile: 'Die Dateien werden derzeit hochgeladen...',
    uploadFileError: 'Die Dateien konnten nicht hochgeladen werden',
    uploadFileSize: 'Die Datei {fileName} ist größer als {fileSize} MB',
    // The text in brackets should not be translated!
    systemError: 'Systemfehler. Bitte versuchen Sie es später noch einmal',
    pageLoading: 'Die Seite wird geladen...',
    pageAccessViolation: 'Sie haben keinen Zugriff auf diese Seite'
  },
  dateFormat: 'dd.mm.yyyy',
  // No translation needed!
  dateMask: '##.##.####',
  // No translation needed!
  dateTimeFormat: 'dd.mm.yyyy hh:mi:ss',
  // No translation needed!
  dateTimeWoSecFormat: 'DD.MM.YYYY HH:mm',
  // No translation needed!
  dateTimeWoSecFormatSpaced: 'DD.MM.YYYY             HH:mm',
  // No translation needed!
  dateTimeWoSecMaskSpaced: '##.##.####             ##:##',
  // No translation needed!
  curLocale: 'de' // No translation needed!
};