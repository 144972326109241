export default {
  menu: {
    account: 'Пользователи',
    role: 'Роли доступа',
    logout: 'Выйти из учётной записи',
    newpsw: 'Сменить пароль',
    company: 'Компании',
    cust: 'Заказчики',
    administrator: 'Администратор',
    request: 'Заявки',
    dblocale: 'Переводы пользовательского интерфейса',
    querysubject: 'Темы запросов пользователей'
  },
  lab: {
    messerr: 'Ошибка',
    messinfo: 'Информация',
    messconf: 'Подтвердите действие',
    addrec: ' (добавление записи)',
    updrec: ' (редактирование записи)',
    viewrec: ' (просмотр записи)',
    captcardAdd: 'Новая запись',
    captcardUpdate: 'Редактирование',
    captcardView: 'Просмотр',
    captcardTab: 'Актуальные записи',
    captcardTabAddle: 'Удаленные записи',
    captcardList: 'Выбор',
    captcardRse: 'Фильтр БД',
    captcardRseAddle: 'Фильтр БД (удаленные)',
    captcardHistory: 'История изменений записи ID: {id}',
    // текст в фигурных скобках нельзя переводить
    hcolId: 'ID штампа',
    hcolActual: 'Актуальность',
    hcolOper: 'Действие',
    hcolStamp: 'Когда',
    hcolUser: 'Кто',
    hoper: {
      I: 'Создана',
      U: 'Изменена',
      D: 'Удалена'
    },
    from: 'с',
    to: 'по',
    Yes: 'Да',
    No: 'Нет'
  },
  act: {
    actOk: 'Выполнить',
    actCan: 'Выйти',
    actReturn: 'Вернуться',
    actSelect: 'Выполнить запрос к БД',
    actAdd: 'Добавить новую запись',
    actUpdate: 'Редактировать',
    actView: 'Детально',
    actDelete: 'Удалить',
    actFind: 'Поиск по таблице',
    actDeleteP: 'Физически удалить помеченные записи',
    actClearRec: 'Очистить карточку',
    actFreshAddleF: 'Показать актуальные записи',
    actFreshAddleA: 'Показать удалённые записи',
    actRepairAddle: 'Восстановить помеченные записи',
    actHistory: 'История изменений',
    actExcel: 'Выгрузить в Excel',
    actFilter: 'Открыть карточку фильтра',
    actDeleteM: 'Удалить помеченные',
    actSwitchMarksOn: 'Включить выбор записей',
    actSwitchMarksOff: 'Отключить выбор записей',
    actExtActs: 'Дополнительные функции',
    save: 'Сохранить',
    cancel: 'Отказаться',
    select: 'Выполнить запрос',
    add: 'Добавить новую запись',
    view: 'Просмотреть запись',
    update: 'Отредактировать помеченные записи',
    delete: 'Удалить помеченные записи',
    choice: 'Выбрать',
    updateOne: 'Редактировать',
    deleteOne: 'Удалить',
    excel: 'Выгрузить в Excel',
    deleteP: 'Физически удалить помеченные записи',
    repairAddle: 'Восстановить помеченные записи',
    menuFresh: 'Перейти к актуальным записям',
    menuAddle: 'Перейти к удаленным записям',
    history: 'История изменений',
    historyhint: 'Просмотреть историю изменений',
    find: 'Поиск',
    yes: 'Да',
    no: 'Нет',
    auth: 'Авторизация',
    addFiles: 'Перетащите файл сюда'
  },
  msg: {
    reqauth: 'Требуется авторизация',
    recSavedSuccess: 'Сохранено успешно',
    confirmDelete: 'Удалить выделенные записи?',
    chkRec: 'Форма заполнена некорректно',
    notFound: 'Не найдено',
    btnClose: 'Закрыть',
    btnCancel: 'Отменить',
    btnOk: 'OK',
    btnSave: 'Сохранить',
    btnApprove: 'В работу',
    fillAllMandatoryFields: 'Заполните все обязательные поля',
    fillMandatoryField: 'Заполните обязательное поле',
    fillMandatoryStringField: 'Заполните обязательное поле',
    fillMandatoryIntField: 'Введите здесь целое число',
    fillMandatoryIntFieldBetween: 'Введите целое число от {minValue} до {maxValue}',
    // текст в фигурных скобках нельзя переводить
    fillNumFieldBetween: 'Введите число от {minValue} до {maxValue}',
    // текст в фигурных скобках нельзя переводить
    fillNumFieldGT: 'Введите число, большее {minValue}',
    // текст в фигурных скобках нельзя переводить
    uploadFile: 'Загрузка файлов...',
    uploadFileError: 'Ошибка загрузки файлов',
    uploadFileSize: 'Размер файла {fileName} превышает {fileSize} МБ',
    // текст в фигурных скобках нельзя переводить
    systemError: 'Системная ошибка. Попробуйте повторить позже',
    pageLoading: 'Страница загружается...',
    pageAccessViolation: 'У Вас нет доступа к этой странице'
  },
  dateFormat: 'dd.mm.yyyy',
  // нельзя переводить
  dateMask: '##.##.####',
  // нельзя переводить
  dateTimeFormat: 'dd.mm.yyyy hh:mi:ss',
  // нельзя переводить
  dateTimeWoSecFormat: 'DD.MM.YYYY HH:mm',
  // нельзя переводить
  dateTimeWoSecFormatSpaced: 'DD.MM.YYYY             HH:mm',
  // No translation needed!
  dateTimeWoSecMaskSpaced: '##.##.####             ##:##',
  // нельзя переводить
  curLocale: 'ru' // нельзя переводить
};