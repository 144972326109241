export var references = {
  // ADMINISTRATOR mode, the first page
  msg: {
    accessRefHeader: 'Access Management',
    accessRefSubheader: 'Users and roles',
    sysRefHeader: 'System Settings',
    sysRefSubheader: 'Cross-functional master data',
    ordinalRefHeader: 'Clients and Contributors',
    ordinalRefSubheader: 'Client- and contributor-specific master data',
    iframeRefHeader: 'Analytics',
    iframeRefSubheader: 'User-specific analytics settings'
  }
};