export var smaProfile = {
  // Company, employee and contributor profiles
  lab: {
    tab: '',
    rec: ''
  },
  addInfo: 'Additional Information',
  kind: 'Contributor type',
  customerName: 'Company',
  jobPosition: 'Position',
  legalFormOoo: 'Limited liability company',
  legalFormAo: 'Joint-stock company',
  legalFormOthers: 'Other',
  kindP: 'Individual',
  kindG: 'Virtual team',
  kindF: 'Legal entity',
  nameP: 'Surname Name',
  nameG: 'Team name',
  nameF: 'Company name',
  email: 'E-mail',
  phone: 'Phone',
  socialProfile: 'Social network profile',
  portfolio: 'Portfolio',
  gmtPlus: 'Time zone',
  countryP: 'Country of registration',
  countryG: 'Country of registration',
  countryF: 'Country of registration',
  note: 'About',
  legalForm: 'Legal form',
  resume: 'Skills',
  langs: 'Languages',
  lang: 'Interface language',
  save: 'Save',
  savedSuccess: 'The profile saved successfully',
  actReviews: 'Reviews',
  sertificats: 'Licenses and certifications'
};