export var smaCustomer = {
  lab: {
    cardRec: '',
    // перевод не требуется
    cardTab: '' // перевод не требуется
  },
  name: 'Компания',
  actReviews: 'Отзывы',
  url: 'Сайт',
  phone: 'Телефон',
  email: 'Е-mail',
  country: 'Страна регистрации',
  note: 'Краткая информация',
  save: 'Сохранить',
  employees: 'Сотрудники',
  employee: 'Сотрудник',
  jobPosition: 'Должность',
  companyRec: 'Компания',
  companyTab: 'Компании',
  requisites: 'Реквизиты',
  urName: 'Юридическое наименование',
  regAddr: 'Адрес регистрации',
  inn: 'ИНН',
  kpp: 'КПП',
  bankReq: 'Банковские реквизиты',
  agreement: 'Договор / соглашение'
};
export var customer = {
  lab: {
    rec: 'Заказчик',
    tab: 'Заказчики',
    name: 'Наименование',
    rate: 'Рейтинг',
    note: 'Краткая информация',
    templateNum: 'Шаблон номера заявки',
    counterNum: 'Счётчик номера заявки'
  },
  act: {},
  msg: {}
};