export var references = {
  // ADMINISTRATOR mode, the first page
  msg: {
    accessRefHeader: 'Zugriffsverwaltung',
    accessRefSubheader: 'Benutzer und Rollen',
    sysRefHeader: 'System-Einstellungen',
    sysRefSubheader: 'Funktionsübergreifende Stammdaten',
    ordinalRefHeader: 'Klienten und Mitwirkende',
    ordinalRefSubheader: 'Klienten- und Mitwirkenden-spezifische Stammdaten',
    iframeRefHeader: 'Analytik',
    iframeRefSubheader: 'Benutzerspezifische Analytik-Einstellungen'
  }
};