export var smaCalendar = {
  lab: {
    cardRec: 'Календарь',
    cardTab: 'Производственный календарь'
  },
  act: {
    actFillWorkDays: 'Пересчитать'
  },
  label: {
    tabCaption: 'Производственный календарь',
    name: 'Календарь',
    gmtPlus: 'Часовой пояс (UTC+)',
    hourStart: 'Начало рабочего дня',
    hourEnd: 'Окончание рабочего дня',
    hourStartGreatestHourEnd: 'Время начала должно быть ранее времени окончания',
    hourInWorkDayTooBig: 'Число рабочих часов слишком велико для указанного интервала',
    hoursInWorkDay: 'Рабочих часов в рабочем дне',
    actFillWorkDaysDateFrom: 'Начальная дата',
    actFillWorkDaysDateTo: 'Конечная дата',
    actFillWorkDaysSelectHoliday: 'Выберите выходные дни:',
    actFillWorkDaysSaturdayIsHoliday: 'Суббота',
    actFillWorkDaysSundayIsHoliday: 'Воскресенье',
    actFillWorkDaysResultOk: 'Пересчёт выполнен успешно',
    actFillWorkDaysResultError: 'Пересчёт завершен с ошибкой'
  }
};
export var smaCalendarDay = {
  lab: {
    cardRec: 'День календаря',
    cardTab: 'День производственного календаря'
  },
  label: {
    tabCaption: 'День производственного календаря',
    calName: 'Наименование календаря',
    dt: 'Дата',
    startTime: 'Начало рабочего дня',
    endTime: 'Окончание рабочего дня',
    isWorkDay: 'Рабочий день',
    weekDay: 'День недели',
    holiday: 'Выходной'
  },
  msg: {
    actChangeWorkFlagResultSuccess: 'Признак "Рабочий день" изменен',
    actChangeWorkFlagResultError: 'Ошибка при изменения признака "Рабочий день"',
    checkEndTime: 'Время окончания должно быть больше времени начала'
  }
};