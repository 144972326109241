//
//
//
//
//
//

export default {
  name: 'App' /*,
              computed: {
              cWindowTitle () { // https://github.com/vuejs/vue-router/issues/914
              // route-name is part of the localisation-key
              const routeName = this.$route.name
              const home = this.$route.path === '/'
              let title = 'Smart-IT'
              if (!home) {
              // only add title extension if this is not the main/home route
              title = `${title} - ${this.$t(`windowTitle.${routeName}`)}`
              }
              return title
              }
              },
              watch: {
              cWindowTitle: 'setWindowTitle'
              },
              created () {
              this.setWindowTitle()
              },
              methods: {
              setWindowTitle () {
              document.title = this.cWindowTitle
              }
              } */
};