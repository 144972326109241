export default {
  // 'Sing in', 'Sign up', 'Send a message' forms
  lab: {
    form: 'Please sign in',
    frmchngpsw: 'Please change your password',
    login: 'User account',
    psw: 'Password',
    welcome: "Don't have an account on the platform yet?",
    newpsw: 'New password',
    oldpsw: 'Old password',
    supform: 'Contributor Sign Up',
    supfio: 'Surname Name',
    supcompkey: 'Company code (specify if you know this code)',
    suppswconf: 'Confirm password',
    suptext1: 'By clicking Sign Up, you agree to the',
    suptext2: 'Terms of Use',
    suptext3: 'and the',
    suptext4: 'Privacy Policy',
    suptext6: 'of the Smart IT platform',
    suptext5: 'Need help?',
    suprefterms: 'https://smartit.digital/documents',
    suprefpolicy: 'https://smartit.digital/documents',
    pswhint: 'At least 8 characters, including UPPER and lowercase, and numbers',
    pswconf: 'Confirm new password',
    qform: 'Please send us your message',
    qlink: 'Send us a message',
    qcompany: 'Company',
    qphone: 'Phone',
    qsubject: 'Subject',
    qmessage: 'Your message',
    qattachment: 'Drag and drop a file here',
    rpform: 'Email Address'
  },
  act: {
    login: 'Sign In',
    reg: 'Join us!',
    supreg: 'Sing Up',
    chngok: 'Change Password',
    qsend: 'Send',
    forgetpsw: 'Forgot password?',
    resetpsw: 'Reset Password'
  },
  msg: {
    toauth: "Authentication is required",
    invalidpsw: "Please check your account or password",
    supcompnotfound: "The company with the code you specified was not found",
    supchkemailpsw: "The password should not match your e-mail",
    pswchkold: "Please check your password",
    pswchanged: "Your password has been changed successfully",
    pswchknewold: "The new password must differ from your previous password",
    pswchknewlogin: "The password should not match your account",
    pswchkdiff: "The confirmation password is different",
    pswchku: "The password you typed does not meet the requirements",
    qsent: "The message sent",
    qsending: "Sending the message",
    qsenderror: "The message failed to send",
    rpresetok: "A new password has been sent to your email",
    suprefinvalid: 'Incorrect URL'
  }
};