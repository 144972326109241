import "core-js/modules/es6.object.freeze.js";
import "core-js/modules/es6.regexp.split.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { i18n } from "../boot/i18n";
export var LANGS = Object.freeze({
  RU: {
    label: 'Русский',
    slabel: 'Рус',
    locale: 'ru',
    icon: 'img:statics/icons/Russia.png'
  },
  EN: {
    label: 'English',
    slabel: 'Eng',
    locale: 'en-us',
    icon: 'img:statics/icons/United-Kingdom.png'
  },
  DE: {
    label: 'Deutsch',
    slabel: 'Deu',
    locale: 'de',
    icon: 'img:statics/icons/Germany.png'
  }
});
export function locale2code(locale) {
  for (var key in LANGS) {
    if (LANGS[key].locale.toUpperCase() === locale.toUpperCase()) {
      return key;
    }
  }
}

// Возвращает объект связанного поля и имя самого поля
function getObjFld(obj) {
  var retObj = {
    objVal: obj.aftobjFrom,
    fldVal: obj.fldValue,
    objFun: obj.aftobjFrom,
    fldFun: obj.actFun
  };
  function parseOne(el) {
    if (retObj['fld' + el]) {
      retObj['fld' + el].split('.').forEach(function (itm, idx, arr) {
        if (idx === arr.length - 1) {
          retObj['fld' + el] = itm;
        } else {
          retObj['obj' + el] = retObj['obj' + el][itm];
        }
      });
    } else {
      retObj['obj' + el] = 'recAdd' in obj.aftobjFrom ? obj.aftobjFrom.recAdd : obj.aftobjFrom;
      retObj['fld' + el] = 'comLang' + el;
    }
  }
  parseOne('Val');
  parseOne('Fun');
  return retObj;
}
export default {
  name: 'langSelector',
  props: {
    aftobjFrom: Object,
    fldValue: null,
    // Имя связанного поля в вызывающем объекте (м.б. составное: recAdd.langcode)
    // Если поле не задано, то работает с i18n.locale
    onform: null,
    // Флаг вызова для формы карточки (рисуем другой лабел)
    actFun: String // Имя call-back функции вызывающего объекта
  },
  data: function data() {
    return {
      langVal: null,
      btnSize: null // [ 'xs', 'sm', 'md', 'lg', 'xl' ],
    };
  },
  created: function created() {
    var fs = getObjFld(this);
    // Если связанное поле не задано, работаем с i18n
    if (fs.fldVal) {
      this.langVal = fs.objVal[fs.fldVal] || locale2code(i18n.locale);
      fs.objVal[fs.fldVal] = this.langVal;
    }
    if (this.onform) {
      this.btnSize = 'md';
    } else {
      this.btnSize = 'md';
    }
  },
  computed: {
    getIcon: function getIcon(code) {
      // if (code) { return LANGS[code].icon }
      if (this.fldvalue) {
        return LANGS[this.langVal].icon;
      } else {
        return LANGS[locale2code(i18n.locale)].icon;
      }
    },
    getLabel: function getLabel() {
      var val = this.fldvalue ? this.langVal : locale2code(i18n.locale);
      if (this.onform) {
        return LANGS[val].label;
      }
      return LANGS[val].slabel;
    }
  },
  methods: {
    getMenuIcon: function getMenuIcon(code) {
      return LANGS[code].icon;
    },
    getMenuLabel: function getMenuLabel(code) {
      return LANGS[code].label;
    },
    changeLang: function changeLang(lVal) {
      var fs = getObjFld(this);
      if (this.fldvalue) {
        this.langVal = lVal;
        // Код выбранного языка переносим в принимающее поле
        fs.objVal[fs.fldVal] = this.langVal;
      }
      // Вызываем callback-функцию
      if (fs.fldFun) {
        fs.objFun[fs.fldFun](lVal);
      }
    }
  } // methods
};