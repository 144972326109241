export var smaCalendar = {
  // ADMINISTRATOR mode, administrating base calendars
  lab: {
    cardRec: 'Kalender',
    cardTab: 'Basis-Kalender'
  },
  act: {
    actFillWorkDays: 'Neuberechnen'
  },
  label: {
    tabCaption: 'Basis-Kalender',
    name: 'Kalender',
    gmtPlus: 'Zeitzone (UTC+)',
    hourStart: 'Beginn',
    hourEnd: 'Ende',
    hourStartGreatestHourEnd: 'Beginn sollte früher als Ende sein',
    hourInWorkDayTooBig: 'Die Anzahl der Arbeitsstunden ist zu hoch für das angegebene Intervall',
    hoursInWorkDay: 'Arbeitsstunden pro Tag',
    actFillWorkDaysDateFrom: 'Datum vom',
    actFillWorkDaysDateTo: 'Datum bis',
    actFillWorkDaysSelectHoliday: 'Wochenenden auswählen:',
    actFillWorkDaysSaturdayIsHoliday: 'Samstag',
    actFillWorkDaysSundayIsHoliday: 'Sonntag',
    actFillWorkDaysResultOk: 'Neuberechnung erfolgreich abgeschlossen',
    actFillWorkDaysResultError: 'Neuberechnung fehlgeschlagen'
  }
};
export var smaCalendarDay = {
  lab: {
    cardRec: 'Kalendertag',
    cardTab: 'Basis-Kalender-Tag'
  },
  label: {
    tabCaption: 'Basis-Kalender-Tag',
    calName: 'Kalender Name',
    dt: 'Datum',
    startTime: 'Beginn',
    endTime: 'Ende',
    isWorkDay: 'Arbeitstag',
    weekDay: 'Wochentag',
    holiday: 'Wochenende'
  },
  msg: {
    actChangeWorkFlagResultSuccess: 'Das Attribut Arbeitstag wurde erfolgreich geändert',
    actChangeWorkFlagResultError: 'Änderung des Arbeitstag-Attributs fehlgeschlagen',
    checkEndTime: 'Ende sollte später als Beginn sein'
  }
};