export var smaProfile = {
  // Company, employee and contributor profiles
  lab: {
    tab: '',
    rec: ''
  },
  addInfo: 'Zusätzliche Informationen',
  kind: 'Art des Mitwirkenden',
  customerName: 'Firma',
  jobPosition: 'Position',
  legalFormOoo: 'Gesellschaft mit beschränkter Haftung',
  legalFormAo: 'Aktiengesellschaft',
  legalFormOthers: 'Andere',
  kindP: 'Person',
  kindG: 'Virtuelles Team',
  kindF: 'Firma',
  nameP: 'Nachname Vorname',
  nameG: 'Team-Name',
  nameF: 'Name der Firma',
  email: 'E-Mail',
  phone: 'Telefon',
  socialProfile: 'Profil des sozialen Netzwerks',
  portfolio: 'Portfolio',
  gmtPlus: 'Zeitzone',
  countryP: 'Land der Eintragung',
  countryG: 'Land der Eintragung',
  countryF: 'Land der Eintragung',
  note: 'Info',
  legalForm: 'Rechtsform',
  resume: 'Fertigkeiten',
  langs: 'Sprachen',
  lang: 'Interface-Sprache',
  save: 'Speichern',
  savedSuccess: 'Das Profil wurde erfolgreich gespeichert',
  actReviews: 'Bewertungen',
  sertificats: 'Bescheinigungen und Zertifikate'
};